import {Link} from 'react-router-dom'
import missingImg from 'images/missing.png'
import styles from './MessageList.module.scss'

const ReceiveMessageBox = ({message, isFirst, isLast, showAvatar}) => (
  <div className={`${styles.message}`}>
    <Link
      to={message.user_id ? `/profile/${message.user_id}` : ''}
      className="user-img-container"
      style={{visibility: showAvatar ? 'visible' : 'hidden'}}>
      <img
        alt=""
        draggable="false"
        src={message.avatar || missingImg}
        className={styles.avatar}
      />
    </Link>
    <div
      className={`${styles.content} ${styles.contentReceive} ${isFirst &&
        styles.firstMessageReceive} ${isLast && styles.lastMessageReceive}`}>
      <div className={styles.textReceive}>{message.text}</div>
      <div className={styles.timeReceive}>{message.time}</div>
    </div>
  </div>
)

export default ReceiveMessageBox
