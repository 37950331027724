import React from 'react'

const Careers = () => (
  <div className="trust-page">
    <div className="container">
      <h3 className="center-align">CAREERS</h3>
      <div className="mt20 mb30">
        <div className="content">
          This is a unique & exciting time to join Ridesurfing. We are a small
          team of intelligent people wanting to make a positive change in our
          society. We are helping people commute long distances while also
          allowing people to share unique experiences with each other.
        </div>
      </div>
      <div className="mt20 mb30">
        <h5>
          <u>Senior Software Engineer</u>
        </h5>
      </div>
      <div className="mt20 mb30">
        <h6>Character</h6>
        <div className="content">
          <ul className="list ml30">
            <li>
              Entrepreneurial, self-motivated, and eager to make meaningful
              social impact by solving technical problems
            </li>
            <li>Brilliant & creative problem-solving abilities</li>
            <li>Ability to work within a collaborative environment</li>
            <li>You enjoy road trips (a plus)</li>
          </ul>
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Qualifications</h6>
        <div className="content">
          <ul className="list ml30">
            <li>
              Masters or PhD in Computer Science, Mathematics, Physics,
              Engineering (or related subject)
            </li>
            <li>Strong CS fundamentals, rigor in engineering</li>
            <li>
              Strong programming skills in Ruby, Sass, Ruby on Rails, Python
            </li>
            <li>Knowledge of SEO (a big plus)</li>
            <li>Knowledge of Perl and shell scripts (a plus)</li>
          </ul>
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Responsibilities</h6>
        <div className="content">
          <ul className="list ml30">
            <li>
              Help shape the future of the Engineering team at Ridesurfing
            </li>
            <li>
              Optimize platform by using advanced techniques to minimize latency
            </li>
            <li>
              Help build efficient pricing model listings with very low
              latencies using complex rules
            </li>
            <li>
              Improving search results with advanced Machine Learning models
              with many ranking features
            </li>
          </ul>
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Benefits</h6>
        <div className="content">
          <ul className="list ml30">
            <li>Competitive salary and/or equity options</li>
            <li>
              Opportunity to make a huge impact in how people travel and meet
              each other
            </li>
            <li>Health club allowances (yoga, meditation)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default Careers
