import React from 'react'

const Policies = () => (
  <div className="aboutUs-page">
    <div className="container">
      <h3 className="center-align">Ridesurfing PRIVACY POLICY</h3>
      <h5 className="center-align">(Updated March 3, 2017)</h5>
      <div className="mt20 mb30">
        <div className="content">
          This Privacy Policy covers the collection, use, and disclosure of
          personal information that may be collected by Ridesurfing, Inc.
          (“Ridesurfing”) anytime you interact with Ridesurfing, such as when
          you visit our website, when you become a member of our website, when
          you purchase the Ridesurfing ride-sharing products and services
          online, or when you call our sales or support associates.
        </div>
      </div>
      <div className="mt20 mb30">
        <div className="content">
          This policy details how data about you is used when you access our
          websites and services (together, "Ridesurfing") or interact with us.
          If we update it, we will revise the date, place notices on Ridesurfing
          if changes are material, and/or obtain your consent as required by
          law.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>1. Protecting Your Privacy.</h6>
        <div className="content">
          We maintain the privacy of your information using security
          technologies and adhere to policies that prevent unauthorized use of
          your personal information.
          <br />
          We take precautions to prevent unauthorized access to or misuse of
          data about you.
          <br />
          We do not run ads, other than the posting by our users.
          <br />
          We do not share your data with third parties for marketing purposes.
          <br />
          We do not engage in cross-marketing or link-referral programs.
          <br />
          We do not send you unsolicited communications for marketing purposes.
          <br />
          We do not engage in affiliate marketing (and prohibit it on
          Ridesurfing).
          <br />
          We do provide email proxy & relay services to reduce unwanted email.
          <br />
          Please review privacy policies of any third-party sites linked to from
          Ridesurfing.
          <br />
          <br />
          While we make every effort to ensure the integrity and security of our
          network and systems, we cannot guarantee that our security measures
          will prevent third-party “hackers” from illegally obtaining your
          personal information.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>2. Integrity of Your Personal Information.</h6>
        <div className="content">
          Data we use to provide/improve our services and/or combat fraud/abuse
          are as follows:
          <br />
          Data you post on or send via Ridesurfing, and/or send us directly or
          via other sites.
          <br />
          Credit card data, which is transmitted to payment processors via a
          security protocol (e.g. SSL).
          <br />
          Data you submit or provide (e.g. name, address, email, phone, fax,
          photos, tax ID).
          <br />
          Web log data (e.g. web pages viewed, access times, IP address, HTTP
          headers).
          <br />
          Data collected via cookies (e.g. search data and "favorites" lists).
          <br />
          Data about your device(s) (e.g. screen size, DOM local storage,
          plugins).
          <br />
          Data from 3rd parties (e.g. phone type, geo-location via IP address).
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>3. Retained Data.</h6>
        <div className="content">
          We retain data as needed for our business purposes and/or as required
          by law.
          <br />
          We make good faith efforts to store data securely, but can make no
          guarantees.
          <br />
          You may access and update certain data about you via your account
          login.
          <br />
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>4. Data Which May Be Disclosed.</h6>
        <div className="content">
          Circumstances in which we may disclose user data are:
          <br />
          To vendors and service providers (e.g. payment processors) working on
          our behalf.
          <br />
          To respond to subpoenas, search warrants, court orders, or other legal
          process.
          <br />
          To protect our rights, property, or safety, or that of users of
          Ridesurfing or the general public.
          <br />
          With your consent (e.g. if you authorize us to share data with other
          users).
          <br />
          In connection with a merger, bankruptcy, or sale/transfer of assets.
          <br />
          In aggregate/summary form, where it cannot reasonably be used to
          identify you.
          <br />
          International Users - By accessing Ridesurfing or providing us data,
          you agree we may use and disclose data we collect as described here or
          as communicated to you, transmit it outside your resident
          jurisdiction, and store it on servers in the United States.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>5. Privacy of Children.</h6>
        <div className="content">
          YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THE Ridesurfing
          WEBSITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED
          TO ACCESS THE Ridesurfing WEBSITE FOR ANY REASON.
          <br />
          <br />
          If you are under 18, you may use this website only through your parent
          or guardian and you are not permitted to post or book Rides or
          purchase other products and services from this website, unless such
          purchase is made through an adult.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>6. Further Questions Regarding Privacy.</h6>
        <div className="content">
          If you have questions or concerns about the Ridesurfing Privacy Policy
          or data processing, please contact us at our email address:{' '}
          <a href="mailto:help@ridesurfing.com">help@ridesurfing.com. </a>
          Naturally, if you notify us that you do not want us to use your
          information for a particular purpose, we will not do so.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>7. Effective Date; Updates to Privacy Policy.</h6>
        <div className="content">
          The terms and conditions in this policy statement came into effect on
          October 1, 2016 and were modified on March 3, 2017. Ridesurfing
          reserves the right to revise, amend, or modify this policy at any time
          and in any manner. When we change the policy in a material way a
          notice will be posted on our website along with the updated privacy
          policy. Your use of our website and any other services provided by
          Ridesurfing after such changes are implemented constitutes your
          acknowledgement and acceptance of these changes. Please consult this
          privacy statement prior to every use for any changes.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>8. Right to Erasure (Right to Be Forgotten) </h6>
        <div className="content">
          You can ask us to erase or delete all or some of your personal data.
          Please email to contact@ridesurfing.com at this time. In the meantime,
          we will continue to develop easier ways of you deleting your data.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>9. Contact Information.</h6>
        <div className="content">
          Ridesurfing welcomes feedback regarding this Privacy Policy. If at any
          time you believe that this website has not adhered to this Privacy
          Policy, please contact us at help@ridesurfing.com or
          www.ridesurfing.com and we will use all commercially reasonable
          efforts to promptly determine and correct the problem.
        </div>
        <h6 className="center-align">NOTICE FOR CALIFORNIA RESIDENTS ONLY</h6>
      </div>
      <div className="mt20 mb30">
        <h6>10. Notice of Compliance to California Residents.</h6>
        <div className="content">
          California law requires that we provide you with a summary of your
          privacy rights under the California Online Privacy Protection Act
          (“California Act”) and the California Business and Professions Code.
          As required by the California Act, this privacy policy identifies the
          categories of personally identifiable information that we collect
          through our website about individual consumers who use or visit our
          website and the categories of third-party persons or entities with
          whom such personally identifiable information may be shared.
          <br />
          Depending on the visitor's activity at our website, certain
          “personally identifiable information” (as that term is defined in the
          California Act) may be collected, in addition to information set forth
          in other sections of this document. For purposes of the California
          Act, the term “personally identifiable information” means individually
          identifiable information about an individual consumer collected online
          by us from an individual and maintained by us in an accessible form,
          and may include any of the following:
          <p className="ml20">
            (1) A first and last name;
            <br />
            (2) A home or other physical address, including street name and name
            of a city or town;
            <br />
            (3) An email address;
            <br />
            (4) A telephone number;
            <br />
            (5) A social security number;
            <br />
            (6) Any other identifier that permits the physical or online
            contacting of a specific individual;
            <br />
            (7) Information concerning a user that the website collects online
            from the user, and maintains in personally identifiable form, in
            combination with an identifier described within this privacy policy.
            <br />
          </p>
          See Section 2 above entitled “Integrity of Your Personal Information”
          for a description of the process maintained by Ridesurfing for an
          individual consumer who uses or visits our website to review and
          request changes to any of his or her personally identifiable
          information that is collected through our website.
          <br />
          <br />
          See Section 7 above entitled “Effective Date; Updates to Privacy
          Policy” for a description of the process by which Ridesurfing notifies
          consumers who use or visit our website of material changes to the
          Ridesurfing Privacy Policy.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>
          11. California Shine the Light Law (SB 27) Compliance Statement.
        </h6>
        <div className="content">
          Under California S.B. 27 (“Shine the Light” Law), California residents
          have the right under certain circumstances to receive, once a year,
          information about third parties with whom we have shared information
          about you or your family for their marketing purposes during the
          previous calendar year, and a description of the categories of
          personal information shared. To make such a request, please send an
          email to help@ridesurfing.com and please include the phrase
          “California Privacy Request” in the subject line, the domain name of
          the website you are inquiring about, along with your name, address and
          email address. We will respond to you within thirty (30) days of
          receiving such a request.
          <br />
        </div>
      </div>
    </div>
  </div>
)

export default Policies
