import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'

import {PrimaryButton} from 'components/Buttons'
import useUserStore from 'store/UserStore'
import {useNavigate} from 'react-router-dom'

const initial_state = {
  otp: '',
  password: '',
  userErrors: {},
  isProcessing: false,
}

const ResetPassword = props => {
  const navigate = useNavigate()
  const {
    errors: userErrors,
    isReset: resetPassword,
    isProcessing,
    resetUserFlagsRequest,
    resetPasswordRequest,
  } = useUserStore()

  const [state, setState] = useState(initial_state)

  useEffect(() => {
    if (resetPassword) {
      resetUserFlagsRequest()
      navigate('/login')
    }
  }, [navigate, resetPassword, resetUserFlagsRequest])

  useEffect(() => {
    if (userErrors) {
      setState(s => ({
        ...s,
        userErrors: userErrors,
        isProcessing: false,
      }))
    }
  }, [userErrors])

  const errorMessageFor = fieldName => {
    if (userErrors && userErrors[fieldName]) {
      return userErrors[fieldName]
    }
  }

  const onFieldChange = (fieldName, event) => {
    setState({
      ...state,
      [fieldName]: event.target.value,
      [`userErrors[${fieldName}]`]: null,
    })
  }

  const onKeyPressEnter = event => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleResetPassword()
    }
  }

  const handleResetPassword = () => {
    const {otp, password} = state
    setState({
      ...state,
      isProcessing: true,
    })
    resetPasswordRequest(otp, password)
  }

  return (
    <div className="login-container">
      <div className="container">
        <Card className="cardContainer">
          <h3 className="center-align">Reset Password</h3>
          <TextField
            fullWidth
            className="text-field"
            id="otp"
            type="text"
            label="Verification Code"
            margin="normal"
            onChange={event => onFieldChange('otp', event)}
            onKeyPress={event => onKeyPressEnter(event)}
          />
          <span className="error">{errorMessageFor('mobile')}</span>
          <TextField
            fullWidth
            className="text-field"
            id="password"
            type="password"
            label="Reset Password"
            margin="normal"
            onChange={event => onFieldChange('password', event)}
            onKeyPress={event => onKeyPressEnter(event)}
          />
          <span className="error">{errorMessageFor('password')}</span>
          <div className="mt40">
            <PrimaryButton
              color="primary"
              buttonName={isProcessing ? 'Please Wait' : 'Reset Password'}
              className="leftIcon-btn"
              disabled={isProcessing}
              handleButtonClick={() => handleResetPassword()}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ResetPassword
