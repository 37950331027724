import {createContext, useContext, useEffect, useState} from 'react'
import ActionCable from 'actioncable'

import useSessionStore from 'store/SessionStore'

export const ActionCableContext = createContext()

const ActionCableProvider = ({children}) => {
  const {accessToken} = useSessionStore()

  const [actionCable, setActionCable] = useState()

  useEffect(() => {
    if (accessToken) {
      const cableObj = ActionCable.createConsumer(
        process.env.REACT_APP_ACTION_CABLE_LINK +
          (accessToken ? accessToken : ''),
      )
      setActionCable(cableObj)
    }
  }, [accessToken])

  return (
    <ActionCableContext.Provider value={{actionCable: actionCable}}>
      {children}
    </ActionCableContext.Provider>
  )
}

const useActionCable = () => {
  const context = useContext(ActionCableContext)

  if (context === undefined) {
    throw new Error('useActionCable must be used within a ActionCableContext')
  }

  return context
}

export {useActionCable}

export default ActionCableProvider
