import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import useTripStore from 'store/TripStore'
import useTripRequestStore from 'store/TripRequestStore'
import {useUser} from 'context/UserProvider'
import missingImg from 'images/missing.png'

import styles from './TripMenu.module.scss'

const TripMenu = ({
  trip,
  on_menu_open,
  on_menu_closed,
  on_trip_cancelled,
  on_request_cancelled,
}) => {
  const {user: currentUser} = useUser()
  const {trips, cancelTripRequest} = useTripStore()
  const {cancelTripRequestRequest} = useTripRequestStore()

  const index = trips?.trip?.id

  const initial_state = {
    id: trip.id,
    anchorEl: null,
    trip: trip,
    is_open: false,
    is_dialog_open: false,
    trip_cancelled: false,
    menu_open_cb: on_menu_open || (() => {}),
    menu_closed_cb: on_menu_closed || (() => {}),
    trip_cancelled_cb: on_trip_cancelled || (() => {}),
    request_cancelled_cb: on_request_cancelled || (() => {}),
  }

  const [state, setState] = useState(initial_state)

  const handleClick = (event) => {
    setState({
      ...state,
      is_open: true,
      anchorEl: event.currentTarget,
    })
    state.menu_open_cb(index)
    // don't forward to parent
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
  }

  const handleClose = () => {
    state.menu_closed_cb(index)
    setState({
      ...state,
      is_open: false,
      anchorEl: null,
    })
  }

  const handleDialogOpen = () => {
    state.menu_open_cb(index)
    setState({
      ...state,
      is_dialog_open: true,
      is_open: false,
      anchorEl: null,
    })
  }

  const handleDialogClose = (event, reason) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
    state.menu_closed_cb(index)
    setState({
      ...state,
      is_dialog_open: false,
    })
  }

  const sendCancelTripRequest = () => {
    cancelTripRequest(trip.id)
    setState({
      ...state,
      is_open: false,
      trip_cancelled: true,
    })
    on_trip_cancelled(trip.id)
  }

  const sendCancelRiderTripRequest = () => {
    const request = trip.relationships.trip_requests.find((x) => {
      return x.requested_by === currentUser.id
    })
    const id = request.id
    cancelTripRequestRequest(id)
    setState({
      ...state,
      is_open: false,
    })
    on_request_cancelled(id)
  }

  const renderDriver = () => {
    const driver_profile = trip?.relationships?.profile?.user?.attributes
      ? `/profile/${trip?.attributes?.driver_id}`
      : ''
    const driver_pfp =
      trip?.relationships?.profile?.user?.attributes?.display_image
    const driver_name =
      trip?.relationships?.profile?.user?.attributes?.name || 'Deleted User'

    return (
      <div className="rider-list">
        <Link to={driver_profile}>
          <div className="rider-img-container">
            <img
              className="responsive-img circle user-img"
              src={driver_pfp || missingImg}
              alt=""
            />
          </div>
          <div className="user-name">{driver_name}</div>
          <div className="user-type">Driver</div>
        </Link>
      </div>
    )
  }

  const renderRiders = () => {
    const trip_requests = state.trip.relationships.trip_requests

    return trip_requests.map((trip_request, index) => {
      if (trip_request.status === 'Accepted') {
        const {requested_by} = trip_request
        const {first_name, last_name, avatar} =
          trip_request?.passenger?.attributes || {}
        const profile_url = trip_request?.passenger?.attributes
          ? `/profile/${requested_by}`
          : ''
        const name = trip_request?.passenger?.attributes
          ? `${first_name} ${last_name}`
          : 'Deleted User'

        return (
          <div className="rider-list" key={`tr_${index}`}>
            <Link to={profile_url}>
              <div className="rider-img-container">
                <img
                  className="responsive-img circle user-img"
                  src={avatar || missingImg}
                  alt=""
                />
              </div>
              <div className="user-name">{name}</div>
              <div className="user-type">Passenger</div>
            </Link>
          </div>
        )
      } else {
        return null
      }
    })
  }

  if (!state.trip) return <div></div>

  const {can_edit, can_cancel, is_expired, is_cancelled} = trip.attributes

  return (
    <div
      className="right"
      onMouseEnter={on_menu_open}
      onMouseLeave={on_menu_closed}>
      <IconButton
        aria-owns={state.is_open ? `simple-menu${index}` : undefined}
        aria-haspopup="true"
        onClick={(event) => handleClick(event)}
        className={styles.dropdown}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`simple-menu${index}`}
        open={state.is_open}
        anchorEl={state.anchorEl}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            transform: 'translateX(-10%)',
            width: 150,
            padding: 0,
          },
        }}
        MenuListProps={{style: {padding: 0}}}
        className="trip-dropdown">
        <MenuItem onClick={() => handleDialogOpen()}>
          <Icon className="menu-icon">list</Icon>View Riders
        </MenuItem>
        {can_edit && !state.trip_cancelled && (
          <Link
            style={{textDecoration: 'none', color: '#4a4a4a'}}
            to={`/edit_ride/${trip?.attributes?.slug || trip.id}`}>
            <MenuItem>
              <Icon className="menu-icon">edit</Icon>Edit Ride
            </MenuItem>
          </Link>
        )}
        {can_cancel && !state.trip_cancelled && (
          <MenuItem onClick={() => sendCancelTripRequest()}>
            <Icon className="menu-icon">delete</Icon>Cancel Ride
          </MenuItem>
        )}
        {!is_expired && !can_cancel && !can_edit && !is_cancelled && (
          <MenuItem onClick={() => sendCancelRiderTripRequest()}>
            <Icon className="menu-icon">delete</Icon>Opt Out
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={state.is_dialog_open || false}
        onClose={handleDialogClose}
        className="dialog-box"
        maxWidth="sm"
        fullWidth={true}>
        <div className="dialog-heading">
          <Icon className="close-icon right" onClick={handleDialogClose}>
            close
          </Icon>
          <h3>Riders list</h3>
        </div>
        <div className="dialog-body">
          {renderDriver(trip)}
          {renderRiders(trip)}
        </div>
      </Dialog>
    </div>
  )
}

export default TripMenu
