import {Route, Routes} from 'react-router-dom'
import Login from 'screens/public/Login'
import Signup from 'screens/public/Signup'
import NoScreen from 'screens/common/NoScreen'
import Home from 'screens/common/Home'
import ForgotPassword from 'screens/public/ForgotPassword'
import ResetPassword from 'screens/public/ResetPassword'
import VerifyEmail from 'screens/public/VerifyEmail'
import Dashboard from 'screens/common/Dashboard/Dashboard'
import Trust from 'screens/common/Trust'
import AboutUs from 'screens/common/AboutUs'
import Help from 'screens/common/Help'
import Careers from 'screens/common/Careers'
import Policies from 'screens/common/Policies'
import Terms from 'screens/common/Terms'
import TripDetails from 'screens/member/TripDetails'

const PublicRouter = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/safety" element={<Trust />} />
    <Route path="/about" element={<AboutUs />} />
    <Route path="/help" element={<Help />} />
    <Route path="/careers" element={<Careers />} />
    <Route path="/policies" element={<Policies />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/forgot_password" element={<ForgotPassword />} />
    <Route path="/reset_password" element={<ResetPassword />} />
    <Route path="/verify_email" element={<VerifyEmail />} />
    <Route path="/search" element={<Dashboard />} />
    <Route path="/ride/:rideId" element={<TripDetails />} />
    <Route path="*" element={<NoScreen />} />
  </Routes>
)

export default PublicRouter
