import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

const NoScreen = () => {
  const navigate = useNavigate()

  console.log('no screen')

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return null
}

export default NoScreen
