import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {use100vh} from 'react-div-100vh'
import useChatStore from 'store/ChatStore'
import ChatUser from 'components/ChatList/ChatUser'
import EmptyChats from 'components/ChatList/EmptyChats'
import CircularLoading from 'ui/CircularLoading/CircularLoading'

const ChatList = () => {
  const navigate = useNavigate()
  const {users, dataLoaded, getChatUsersRequest} = useChatStore()

  useEffect(() => {
    getChatUsersRequest()
  }, [getChatUsersRequest])

  const loadChat = userId => {
    localStorage.setItem('directChatUserId', userId)
    navigate('/chat')
  }

  const height = use100vh()

  if (!dataLoaded) {
    return <CircularLoading />
  }
  return (
    <div className="chatList-page" style={{height}}>
      <div className="row chat-list-sm">
        <div className="col s12 user-name center-align">
          <span>Users List</span>
        </div>
      </div>

      <div className="row mb0" style={{flexGrow: 1, overflow: 'auto'}}>
        {users.length > 0 ? (
          <div className="col s12 user-list">
            <div className="user-chat-list">
              <ul className="friend-list">
                {users.map((user, key) => (
                  <ChatUser key={key} user={user} onLoadChat={loadChat} />
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <EmptyChats />
        )}
      </div>
    </div>
  )
}

export default ChatList
