import {Link} from 'react-router-dom'
import noChat from 'images/No-chat.png'
import styles from './ChatList.module.scss'

const EmptyChats = () => (
  <div className={styles.emptyChat}>
    <div className="landing-heading center-align">
      <img src={noChat} className="chat-icon img-responsive" alt="" />
      <h4>No messages yet!</h4>
      <p>
        You chat will live here once you start sharing messages with
        Ridesurfing.
      </p>
      <Link className="btn btn-sm dashboard-btn" to="/search">
        Search
      </Link>
    </div>
  </div>
)
export default EmptyChats
