import _ from 'underscore'
import React, {useEffect} from 'react'

import CircularLoading from 'ui/CircularLoading/CircularLoading'
import useReviewStore from 'store/ReviewStore'
import styles from './Reviews.module.scss'
import ReviewCard from 'components/ReviewCard/ReviewCard'

const Review = ({tripErrors}) => {
  const {reviews, dataLoaded, getReviewsRequest} = useReviewStore()

  useEffect(() => {
    getReviewsRequest()
  }, [getReviewsRequest])

  const renderReviews = () => {
    return reviews.map((review, key) => {
      const {trip, user} = review?.relationships || {}
      return <ReviewCard key={key} trip={trip} user={user} review={review} />
    })
  }

  return (
    <div className="my-trips">
      <h4>Reviews</h4>
      <hr className="mb20" />

      <div className="trips-container">
        {reviews.length > 0 ? (
          renderReviews()
        ) : dataLoaded ? (
          <h4 className={styles.empty}>No Pending Reviews!</h4>
        ) : (
          <CircularLoading noPadding />
        )}
      </div>
    </div>
  )
}

export default Review
