import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import {confirmAlert} from 'react-confirm-alert'
import {useUser} from 'context/UserProvider'
import {PrimaryButton} from 'components/Buttons'
import useSessionStore from 'store/SessionStore'
import styles from './ProfileAccountSection.module.scss'

const gender = ['Male', 'Female', 'Other']

const MAX_DATE = moment().subtract(18, 'years').toDate()

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
}

const ProfileAccountSection = () => {
  const navigate = useNavigate()
  const {user: currentUser, setUser: updateUser} = useUser()
  const {
    userErrors: currentUserErrors,
    userUpdated,
    passwordUpdated,
    resetCurrentUserFlagsRequest,
    updateUserRequest,
    changeUserPasswordRequest,
    deleteAccountRequest,
  } = useSessionStore()

  const [user, setUser] = useState(currentUser?.attributes || {})
  const [accountProcessing, setAccountProcessing] = useState(false)
  const [passwordProcessing, setPasswordProcessing] = useState(false)

  useEffect(() => {
    if (userUpdated || passwordUpdated) {
      resetCurrentUserFlagsRequest()

      setUser((u) => ({
        ...u,
        current_password: '',
        password: '',
      }))
    }
  }, [userUpdated, passwordUpdated, resetCurrentUserFlagsRequest])

  const onFieldChange = (fieldName, event) => {
    setUser((u) => ({
      ...u,
      [fieldName]: event.target.value,
    }))
  }

  const onDateChange = (fieldName, date) => {
    setUser((u) => ({
      ...u,
      [fieldName]:
        date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear(),
    }))
  }

  const errorMessageFor = (fieldName) => {
    if (currentUserErrors && currentUserErrors[fieldName]) {
      return currentUserErrors[fieldName]
    }
  }

  const handleAccountSave = async () => {
    setAccountProcessing(true)
    await updateUserRequest(currentUser.id, user)
    setAccountProcessing(false)
  }

  const handleUpdatePassword = async () => {
    setPasswordProcessing(true)
    await changeUserPasswordRequest(user)
    setPasswordProcessing(false)
  }

  const handleDeleteAction = () => {
    confirmAlert({
      title: 'Alert!',
      message: 'Are you sure you delete your account?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await deleteAccountRequest()
            updateUser(null)
            navigate('/login')
          },
        },
        {
          label: 'No',
          onClick: () => console.log('canceled'),
        },
      ],
    })
  }

  return (
    <div className="profile-account-section">
      <div className="row">
        <div className="col s12 m12">
          <h5>Account Information</h5>
          <div className="mb10">
            <TextField
              fullWidth
              className="text-field"
              id="firstname"
              type="text"
              label="First Name"
              margin="normal"
              value={user.first_name || ''}
              onChange={(event) => onFieldChange('first_name', event)}
            />
            <span className="error">{errorMessageFor('first_name')}</span>
          </div>
          <div className="mb10">
            <TextField
              fullWidth
              className="text-field"
              id="lastname"
              type="text"
              label="Last Name"
              margin="normal"
              value={user.last_name || ''}
              onChange={(event) => onFieldChange('last_name', event)}
            />
            <span className="error">{errorMessageFor('last_name')}</span>
          </div>
          <div className="mb10">
            <TextField
              fullWidth
              className="text-field"
              id="email"
              type="text"
              label="Email"
              margin="normal"
              value={user.email || ''}
              onChange={(event) => onFieldChange('email', event)}
            />
            <span className="error">{errorMessageFor('email')}</span>
          </div>
          <div className="mb10">
            <div className="date-label">Date of Birth</div>
            <div className="date-picker-field">
              <DatePicker
                selected={!!user.birthday ? moment(user.birthday).toDate() : ''}
                onChange={(date) => onDateChange('birthday', date)}
                maxDate={MAX_DATE}
                showYearDropdown
                dropdownMode="select"
                placeholderText="MM/DD/YYYY"
                className={['date-field text-field', styles.dob].join(' ')}
              />
            </div>
            <span className="error">{errorMessageFor('birthday')}</span>
          </div>
          <div className="mb10">
            <FormControl className="selectField">
              <InputLabel className="selectLabel" htmlFor="select-multiple">
                Select Gender
              </InputLabel>
              <Select
                value={user.gender || ''}
                onChange={(event) => onFieldChange('gender', event)}
                input={<Input id="select-multiple" />}
                MenuProps={MenuProps}
                className="selected-menu-field">
                {gender.map((name) => (
                  <MenuItem key={name} value={name} className="menu-field">
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mt20 mb20">
            <PrimaryButton
              color="primary"
              buttonName={accountProcessing ? 'Please Wait...' : 'Save'}
              className="lg-primary"
              handleButtonClick={() => handleAccountSave()}
              disabled={!!accountProcessing}
            />
          </div>
          <h5>Change Password</h5>
          <div className="mb10">
            <TextField
              fullWidth
              className="text-field"
              id="current_password"
              type="password"
              label="Current Password"
              margin="normal"
              value={user.current_password || ''}
              onChange={(event) => onFieldChange('current_password', event)}
            />
            <span className="error">{errorMessageFor('current_password')}</span>
          </div>
          <div className="mb10">
            <TextField
              fullWidth
              className="text-field"
              id="password"
              type="password"
              label="New Password"
              margin="normal"
              value={user.password || ''}
              onChange={(event) => onFieldChange('password', event)}
            />
            <span className="error">{errorMessageFor('password')}</span>
          </div>
          <div className="mt20 mb20">
            <PrimaryButton
              color="primary"
              buttonName={
                passwordProcessing ? 'Please Wait...' : 'Update Password'
              }
              className="lg-primary"
              handleButtonClick={() => handleUpdatePassword()}
              disabled={!!passwordProcessing}
            />
          </div>

          <h5>Delete Account</h5>
          <div className="mt20 mb20">
            <PrimaryButton
              color="primary"
              buttonName={'Delete Account'}
              className="lg-primary"
              handleButtonClick={() => handleDeleteAction()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileAccountSection
