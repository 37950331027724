import React from 'react'

const Trust = () => (
  <div className="trust-page">
    <div className="container">
      <h3 className="center-align">HELP</h3>
      <div className="mt20 mb30">
        <div className="content">
          For any inquiries please contact{' '}
          <a href="mailto:help@ridesurfing.com">help@ridesurfing.com</a>
        </div>
      </div>
    </div>
  </div>
)

export default Trust
