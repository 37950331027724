import {create} from 'zustand'
import callApi from 'util/apiCaller'
import {notify} from 'react-notify-toast'

const useTripStore = create(set => ({
  allTrips: [],
  trips: [],
  searchedTrips: [],
  similarTrips: [],
  waypoints: [],
  errors: [],
  isSaved: false,
  dataLoaded: false,
  isDeleted: false,
  isCompleted: false,
  isBooked: false,
  isProcessing: false,
  error: '',
  trip: {
    attributes: {},
    relationships: {
      profile: {
        user: {
          attributes: {},
          relationships: {reviews: {}},
        },
      },
    },
  },
  isCancelled: false,
  pagination: {
    current_page: 1,
    per_page: 5,
    total_pages: 1,
    total_count: 5,
  },
  searchPagination: {
    current_page: 1,
    per_page: 5,
    total_pages: 1,
    total_count: 5,
  },

  getTripsRequest: async (page = 1) => {
    const res = await callApi(`trips/current?page=${page}`, 'get')
    if (res.error || res.errors) {
      set({
        errors: res.errors,
        error: res.error,
        isProcessing: false,
      })
    } else {
      set({
        trips: res.data,
        pagination: res.pagination,
        dataLoaded: true,
        isProcessing: false,
      })
    }
  },
  searchTripIdsRequest: async (query = {}, page = 1, waypoints = true) => {
    set({dataLoaded: false})
    const no_waypoints = waypoints ? '' : '&no_waypoints=1'
    const res = await callApi(
      `trips/search?page=${page}${no_waypoints}`,
      'post',
      query,
    )
    if (!res) {
      notify.show('internet connection error.', 'error')
      set({
        isProcessing: false,
      })
    } else {
      if (res.error || res.errors) {
        set({
          errors: res.errors,
          error: res.error,
          isProcessing: false,
        })
      } else {
        set({
          searchedTrips: res.data,
          similarTrips: res.similar,
          allTrips: res.all_trips,
          searchPagination: res.pagination,
          waypoints: res.waypoints,
          dataLoaded: true,
          isProcessing: false,
        })
      }
    }
  },
  getTripRequest: tripId => {
    set({isProcessing: true})
    callApi(`trips/${tripId}`).then(res => {
      if (!res) {
        set({isProcessing: false})
      } else {
        if (!res.error && !res.errors) {
          set({
            trip: res.data,
            isProcessing: false,
          })
        } else {
          set({
            errors: res.errors,
            error: 'Trip not found',
            isProcessing: false,
          })
        }
      }
    })
  },
  getTripInfoRequest: async tripId => {
    const res = await callApi(`trips/trip/${tripId}`, 'get')
    if (!res) {
    } else {
      if (res && !res.error && !res.errors) {
        set({
          trip: res.data,
          isProcessing: false,
        })
      } else {
        set({
          errors: res.errors,
          error: 'Trip not found',
          isProcessing: false,
        })
      }
    }

    return res
  },
  createTripRequest: async params => {
    set({isProcessing: true})
    const res = await callApi(`trips`, 'post', params)
    if (res.errors) {
      set({
        errors: res.errors,
        error: res.error,
        isProcessing: false,
      })
    } else {
      set(state => ({trips: state.trips.splice(0, 0, res.data)}))
      set({
        trip: res.data,
        isSaved: true,
        isProcessing: false,
      })
    }

    return res
  },
  updateTripRequest: async (tripId, params) => {
    set({isProcessing: true})
    const res = await callApi(`trips/${tripId}.json`, 'put', params)
    if (res.errors) {
      set({
        errors: res.errors,
        error: res.error,
        isProcessing: false,
      })
    } else {
      set({
        trip: res.data,
        isCompleted: true,
        isProcessing: false,
      })
    }

    return res
  },
  bookTripRequest: async (tripId, params) => {
    const res = await callApi(`trips/${tripId}/trip_requests`, 'post', params)
    if (res.errors) {
      set({
        errors: res.errors,
        error: res.error,
        isProcessing: false,
      })
    } else {
      set({
        isBooked: true,
        isProcessing: false,
      })
    }
  },
  deleteTripRequest: tripId => {
    callApi(`trips/${tripId}`, 'delete').then(res => {
      if (res.errors) {
        set({
          errors: res.errors,
          error: res.error,
          isProcessing: false,
        })
      } else {
        set({isDeleted: true})
      }
    })
  },
  resetTripFlagRequest: () => {
    set({
      errors: [],
      error: '',
      isSaved: false,
      isBooked: false,
      isCompleted: false,
      isDeleted: false,
      isCancelled: false,
      isProcessing: false,
      trip: {
        attributes: {},
        relationships: {
          trip_requests: [],
          profile: {user: {attributes: {}, relationships: {reviews: []}}},
          attributes: {},
          relationships: {},
        },
      },
    })
  },
  resetDataLoadedRequest: () => {
    set({dataLoaded: false})
  },
  cancelTripRequest: tripId => {
    callApi(`trips/${tripId}/cancel`, 'delete').then(res => {
      if (res.errors) {
        set({
          errors: res.errors,
          error: res.error,
          isProcessing: false,
        })
      } else {
        set({
          trip: res.data,
          isCancelled: true,
          isProcessing: false,
        })
      }
    })
  },
  setProcessingRequest: () => {
    set({isProcessing: true})
  },
}))

export default useTripStore
