import React, {useEffect, useCallback, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useActionCable} from 'context/ActionCableProvider'
import {useUser} from 'context/UserProvider'
import noChat from 'images/No-chat.png'
import useChatStore from 'store/ChatStore'
import Chats from 'components/Chats/Chats'
import missingImg from 'images/missing.png'
import ChatUser from 'components/ChatList/ChatUser'
import {use100vh} from 'react-div-100vh'
import styles from './Chat.module.scss'
import CircularLoading from 'ui/CircularLoading/CircularLoading'

const Chat = () => {
  const {actionCable} = useActionCable()
  const {user: currentUser} = useUser()
  const chatSubscription = useRef()
  const [loading, setLoading] = useState(true)
  const [focused, setFocused] = useState(false)

  const {
    chats,
    users,
    user: chatUser,
    sendChatRequest,
    getChatUsersRequest,
    getDirectChatUserRequest,
  } = useChatStore()

  const subscribeChannel = useCallback(() => {
    chatSubscription.current = actionCable?.subscriptions?.create(
      {channel: 'ChatsChannel'},
      {
        connected: () => {
          console.log('Chat Channel connected')
        },
        disconnected: () => {
          console.log('Chat Channel disconnected')
        },
        received: function(data) {
          if (
            (chatUser.id === data.sender_id &&
              currentUser?.id === data.receiver_id) ||
            (chatUser.id === data.receiver_id &&
              currentUser?.id === data.sender_id)
          ) {
            getDirectChatUserRequest(chatUser.id, true)
          }
        },
      },
    )
  }, [
    actionCable?.subscriptions,
    chatUser.id,
    currentUser?.id,
    getDirectChatUserRequest,
  ])

  const loadChat = useCallback(
    async userId => {
      setLoading(true)
      await getChatUsersRequest()
      localStorage.setItem('directChatUserId', userId)
      await getDirectChatUserRequest(userId, true)
      setLoading(false)
    },
    [getChatUsersRequest, getDirectChatUserRequest],
  )

  useEffect(() => {
    if (actionCable) {
      subscribeChannel()
    }

    return () => {
      actionCable?.subscriptions?.remove(chatSubscription.current)
    }
  }, [actionCable, subscribeChannel])

  useEffect(() => {
    if (localStorage.directChatUserId) {
      loadChat(localStorage.directChatUserId)
    }
  }, [loadChat])

  const sendChat = message => {
    sendChatRequest({
      message: message.trim().replace(/(\r\n|\n|\r)/gm, ''),
      receiver_id: chatUser.id,
    })
  }

  const otherUsers = users.filter(user => user.id !== chatUser.id)
  const height = use100vh()

  return (
    <div className="chat-page" style={{height: !focused ? height : '100vh'}}>
      {users.length < 0 ? (
        <div className="new-user">
          <div className="landing-heading center-align">
            <img src={noChat} className="chat-icon img-responsive" alt="" />
            <h4>No messages yet!</h4>
            <p>
              Your chat will live here once you start sharing messages with
              Ridesurfing.
            </p>
            <Link className="btn btn-sm dashboard-btn" to="/search">
              Go to Search
            </Link>
          </div>
        </div>
      ) : (
        <div className={['row mb0', styles.container].join(' ')}>
          {!loading && (
            <div className={['row chat-list-sm', styles.width100p].join(' ')}>
              <div className="col s2 mt10">
                <Link to="/chatList" className="chatList">
                  <i className="fa fa-chevron-left" />
                </Link>
              </div>
              <div className="col s7 user-name center-align">
                <span>
                  {chatUser.attributes.first_name
                    ? `${chatUser.attributes.first_name} ${chatUser.attributes.last_name}`
                    : 'Deleted User'}
                </span>
              </div>
              <div className="col s3">
                <Link
                  to={
                    chatUser.attributes.first_name
                      ? `/profile/${chatUser.attributes.slug || chatUser.id}`
                      : ''
                  }
                  className="user-img-container">
                  <img
                    src={
                      chatUser.attributes.first_name
                        ? chatUser.attributes.small_image_url || missingImg
                        : missingImg
                    }
                    className="responsive-img user-img"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          )}
          <div className="col l3 s12 user-list">
            <div className="user-chat-list">
              <ul className="friend-list">
                {chatUser.id && (
                  <ChatUser user={chatUser} isActive onLoadChat={() => {}} />
                )}

                {otherUsers.map((user, key) => (
                  <ChatUser key={key} user={user} onLoadChat={loadChat} />
                ))}
              </ul>
            </div>
          </div>
          <div className={['col l9 s12', styles.height100p].join(' ')}>
            <div className={['row mb0', styles.height100p].join(' ')}>
              <div className="col l4 chat-list-user-details pl0 pr0">
                <div className="user-details center-align">
                  <Link
                    to={
                      chatUser.attributes.first_name
                        ? `/profile/${chatUser.attributes.slug || chatUser.id}`
                        : ''
                    }
                    className="user-img-container">
                    <img
                      src={
                        chatUser.attributes.first_name
                          ? chatUser.attributes.small_image_url || missingImg
                          : missingImg
                      }
                      className="responsive-img"
                      alt=""
                    />
                  </Link>
                  <h5>{chatUser.attributes.name}</h5>
                  <table className="table table-user-information">
                    <tbody>
                      <tr>
                        <td className="info-label">
                          <b>Gender</b>
                        </td>
                        <td className="info-val">
                          {chatUser.attributes.gender}
                        </td>
                      </tr>
                      <tr>
                        <td className="info-label">
                          <b>Age</b>
                        </td>
                        <td className="info-val">
                          {chatUser.attributes.age} Years
                        </td>
                      </tr>
                      {!!chatUser.attributes.kids && (
                        <tr>
                          <td className="info-label">
                            <b>Kids</b>
                          </td>
                          <td className="info-val">
                            {chatUser.attributes.kids}
                          </td>
                        </tr>
                      )}
                      {!!chatUser.attributes.relationship_status && (
                        <tr>
                          <td className="info-label">
                            <b>Relationship</b>
                          </td>
                          <td className="info-val">
                            {chatUser.attributes.relationship_status}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="social-icon">
                    {!!chatUser.attributes.facebook_link && (
                      <a
                        href={chatUser.attributes.facebook_link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className="ml20">
                          <i className="fa fa-facebook success" />
                        </span>
                      </a>
                    )}
                    {!!chatUser.attributes.instagram_link && (
                      <a
                        href={chatUser.attributes.instagram_link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className="ml20">
                          <i className="fa fa-instagram danger" />
                        </span>
                      </a>
                    )}
                    {!!chatUser.attributes.linkedin_link && (
                      <a
                        href={chatUser.attributes.linkedin_link}
                        target="_blank"
                        rel="noopener noreferrer">
                        <span className="ml20">
                          <i className="fa fa-linkedin success" />
                        </span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={[
                  'col l8 s12 pl0 pr0',
                  styles.height100p,
                  styles.chatContainer,
                ].join(' ')}
                style={{position: 'relative'}}>
                {loading ? (
                  <CircularLoading noPadding />
                ) : (
                  <Chats
                    messages={chats}
                    isDeletedUser={!chatUser.attributes.first_name}
                    onSend={sendChat}
                    onFocus={() => {
                      setFocused(true)
                    }}
                    onBlur={() => {
                      setFocused(false)
                      window.scrollTo(0, 0)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Chat
