import {useRef, useEffect, useCallback} from 'react'
import SendMessageBox from './SendMessageBox'
import ReceiveMessageBox from './ReceiveMessageBox'
import styles from './MessageList.module.scss'
import {useUser} from 'context/UserProvider'

const MessageList = ({messages = []}) => {
  const {user: currentUser} = useUser()
  const messageListRef = useRef()

  let lastMsgId = ''
  if (messages.length > 0) {
    lastMsgId = messages[0].id
  }

  const scrollToBottom = useCallback(() => {
    messageListRef.current.parentNode.scrollTop =
      messageListRef.current.parentNode.scrollHeight
  }, [])

  useEffect(() => {
    if (messageListRef.current) {
      scrollToBottom()
    }
  }, [lastMsgId, scrollToBottom])

  const messageList = messages.map((message, index, arr) => {
    const prevMessage = arr[index + 1]
    const nextMessage = arr[index - 1]
    const isReceived = message.position === 'left'
    const isFirst = prevMessage?.position !== message.position
    const isLast = nextMessage?.position !== message.position
    const newDate = prevMessage?.date !== message.date
    const showAvatar =
      nextMessage?.date !== message.date ||
      nextMessage?.user_id !== message.user_id

    return (
      <div key={index}>
        {newDate && <div className={styles.date}>{message.date}</div>}
        {isReceived ? (
          <ReceiveMessageBox
            message={message}
            isFirst={isFirst}
            isLast={isLast}
            showAvatar={showAvatar}
          />
        ) : (
          <SendMessageBox
            message={message}
            isFirst={isFirst}
            isLast={isLast}
            showAvatar={showAvatar}
            avatar={currentUser?.attributes?.avatar}
          />
        )}
      </div>
    )
  })

  return (
    <div className={styles.container} ref={messageListRef}>
      {messageList}
    </div>
  )
}

export default MessageList
