import React, {useEffect} from 'react'
import {MuiThemeProvider, createTheme} from '@material-ui/core/styles'
import Notifications from 'react-notify-toast'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {useUser} from 'context/UserProvider'
import MemberRouter from 'routes/member'
import PublicRouter from 'routes/public'
import ActionCableProvider from 'context/ActionCableProvider'
import {useLocation} from 'react-router-dom'
import styles from './container.module.scss'
import {use100vh} from 'react-div-100vh'

const theme = createTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#3399ff',
      dark: '#3399ff',
      contrastText: '#fff',
    },
    danger: {
      light: '#000',
      main: 'red',
      dark: 'red',
      contrastText: '#fff',
    },
    secondary: {
      main: '#3b5998',
      contrastText: '#fff',
      dark: '#3b5998',
    },
    action: {
      hoverOpacity: 0.08,
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: '#4a4a4a',
      secondary: '#9a9a9a',
      disabled: '#eaeaea',
      hint: '#f9f9f9',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    buttonNext: {
      fontSize: 14,
      color: '#fff',
      textTransform: 'initial',
    },
    button: {
      fontSize: 14,
      color: '#fff',
      textTransform: 'initial',
    },
  },
})

const SharedContainer = props => {
  const location = useLocation()
  const {user} = useUser()

  useEffect(() => {
    if (location.pathname === '/chatList' || location.pathname === '/chat') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = ''
    }
  }, [location.pathname])

  return (
    <MuiThemeProvider theme={theme}>
      <ActionCableProvider>
        <div className={styles.rootContainer} style={{minHeight: use100vh()}}>
          <Nav />
          <Notifications options={{zIndex: 1200}} />
          {user ? <MemberRouter /> : <PublicRouter />}

          {location.pathname !== '/chatList' &&
            location.pathname !== '/chat' && <Footer />}
        </div>
      </ActionCableProvider>
    </MuiThemeProvider>
  )
}

export default SharedContainer
