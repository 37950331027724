import _ from 'underscore'
import React, {useState, useEffect, useCallback} from 'react'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import Dropzone from 'react-dropzone'
import Switch from 'react-switch'
import ReactLoading from 'react-loading'
import ProfilePayoutSection from 'components/ProfilePayoutSection'
import useSessionStore from 'store/SessionStore'

import missingImg from 'images/missing.png'
import {useLocation, useNavigate} from 'react-router-dom'
import {useUser} from 'context/UserProvider'
import {PrimaryButton} from 'components/Buttons'

const carColor = [
  'Black',
  'Blue',
  'Red',
  'Yellow',
  'White',
  'Green',
  'Brown',
  'Gray',
  'Gold',
  'Other',
]
const MenuProps = {PaperProps: {style: {maxHeight: 300}}}

const ProfileChecklist = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {user: currentUser, setUser} = useUser()
  const {
    profileErrors,
    carMakeList,
    profileSaved,
    isProcessing,
    isCarImageProcessing,
    isPayoutProcessing,
    imageUploaded,
    accountUpdated,
    carMakeListRequest,
    resetProfileFlagsRequest,
    setProcessingRequest,
    uploadProfileImageRequest,
    saveProfileRequest,
    setAccountProcessingRequest,
  } = useSessionStore()

  const has_payout_details = currentUser?.attributes?.has_payout_details

  const hasCarImage = useCallback(() => {
    const images =
      currentUser?.relationships?.profile?.relationships?.images || []

    return images.find(image => image?.attributes?.image_type === 'car')
  }, [currentUser])

  const hasDisplayImage = useCallback(() => {
    const images =
      currentUser?.relationships?.profile?.relationships?.images || []
    return images.find(image => image?.attributes?.image_type === 'display')
  }, [currentUser])

  const initial_state = {
    drive_created: location?.state?.drive_created ? true : false,
    price: location?.state?.price,
    profile: {
      is_driver: true,
    },
  }

  const [state, setState] = useState(initial_state)

  useEffect(() => {
    carMakeListRequest()
  }, [carMakeListRequest])

  useEffect(() => {
    setState(s => ({
      ...s,
      profile: {
        ...s.profile,
        car_make: currentUser?.relationships?.profile?.attributes?.car_make,
        car_model: currentUser?.relationships?.profile?.attributes?.car_model,
        car_year: currentUser?.relationships?.profile?.attributes?.car_year,
        car_color: currentUser?.relationships?.profile?.attributes?.car_color,
      },
    }))
  }, [currentUser])

  useEffect(() => {
    if (currentUser && !imageUploaded && !accountUpdated) {
      const profile = currentUser.relationships.profile.attributes
      profile['is_driver'] = true
      setState(s => ({...s, profile}))
    }
  }, [currentUser, imageUploaded, accountUpdated])

  useEffect(() => {
    if (profileSaved && Object.keys(profileErrors).length === 0) {
      resetProfileFlagsRequest()
    }
  }, [profileSaved, profileErrors, resetProfileFlagsRequest])

  const displayImage = imageType => {
    const images =
      currentUser?.relationships?.profile?.relationships?.images || []
    const image = images.find(
      image => image?.attributes?.image_type === imageType,
    )
    return image ? image?.attributes?.url : missingImg
  }

  const onCancel = () => {}

  const errorMessageFor = fieldName => {
    if (profileErrors && profileErrors[fieldName]) {
      return profileErrors[fieldName]
    }
  }

  const onFieldChange = (fieldName, event) => {
    const {profile} = state
    let tmp = {...profile}
    tmp[fieldName] = event.target.value

    if (fieldName === 'car_make') {
      tmp['car_model'] = null
      tmp['car_year'] = null
      tmp['car_color'] = null
    }
    setState(s => ({
      ...s,
      profile: tmp,
    }))
  }

  const onToggleChange = (fieldName, checked) => {
    const {profile} = state
    let tmp = JSON.parse(JSON.stringify(profile))
    tmp[fieldName] = checked

    setState(s => ({
      ...s,
      profile: tmp,
    }))
  }

  const carModelList = () => {
    const {car_make} = state.profile

    if (car_make && carMakeList[car_make]) {
      const models = carMakeList[car_make].car_models
      return _.map(models, val => {
        return {value: val.model_name}
      })
    } else {
      return []
    }
  }

  const carYearList = () => {
    const {car_make, car_model} = state.profile

    if (car_make && carMakeList[car_make]) {
      const models = carMakeList[car_make].car_models
      const selectedModel = _.find(models, {model_name: car_model})

      if (selectedModel) {
        return _.map(selectedModel.years, val => {
          return {value: val}
        })
      } else {
        return []
      }
    } else {
      return []
    }
  }

  const uploadImage = (files, imageType) => {
    const fileObj = files[0]

    if (fileObj) {
      var FR = new FileReader()
      FR.addEventListener('load', async e => {
        setAccountProcessingRequest(imageType)
        const res = await uploadProfileImageRequest(imageType, e.target.result)

        if (res) {
          setUser(res.data)
        }
      })

      FR.readAsDataURL(fileObj)
    }
  }

  const handleProfileSave = async () => {
    const {profile} = state

    setProcessingRequest()
    const res = await saveProfileRequest(currentUser.id, profile)
    if (res) {
      setUser(res.data)
    }
  }

  const originalyHasCarBasicInfo = () => {
    return (
      !!currentUser.relationships.profile.car_make &&
      !!currentUser.relationships.profile.car_model &&
      !!currentUser.relationships.profile.car_year &&
      !!currentUser.relationships.profile.car_color
    )
  }

  const carInfoSaved = useCallback(() => {
    const profile = currentUser.relationships?.profile?.attributes

    return (
      profile?.car_make &&
      profile?.car_model &&
      profile?.car_year &&
      profile?.car_color &&
      !!hasCarImage()
    )
  }, [currentUser, hasCarImage])

  useEffect(() => {
    console.log('state.price', typeof state.price)
    if (
      carInfoSaved() &&
      hasDisplayImage() &&
      (!state.price || Number(state.price) === 0 || has_payout_details)
    ) {
      navigate('/my_rides')
    }
  }, [has_payout_details, navigate, hasDisplayImage, state.price, carInfoSaved])

  const {profile, drive_created, price} = state

  return (
    <div className="edit-profile-page">
      <div className="container">
        <div className="row">
          <div className="col l3 s12 center-align">
            <div className="user-img-container">
              <img
                src={displayImage('display')}
                className="user-img responsive-img"
                alt=""
              />
            </div>
            <span className="error">{errorMessageFor('is_driver')}</span>
            <div className="mb20 mt20">
              <div className="bubble-container">
                {isProcessing && (
                  <ReactLoading
                    type="bubbles"
                    color="#3399ff"
                    height="50px"
                    width="50px"
                  />
                )}
              </div>
              <Dropzone
                onDrop={files => uploadImage(files, 'display')}
                onFileDialogCancel={onCancel}
                className="dropzone">
                {({getRootProps, getInputProps}) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div>
                        Try dropping image here, or click to select image to
                        upload. Size should be less than 3 MB.
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="row mt20 user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you allow smoking?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.smoking}
                  onChange={(checked, event, id) =>
                    onToggleChange('smoking', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you allow pets?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.pets}
                  onChange={(checked, event, id) =>
                    onToggleChange('pets', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you have or prefer ac?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.car_ac}
                  onChange={(checked, event, id) =>
                    onToggleChange('car_ac', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Are you open to traveling with kids?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.kid_friendly}
                  onChange={(checked, event, id) =>
                    onToggleChange('kid_friendly', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
          </div>
          <div className="col offset-l1 l8 s12 right-side">
            {!!drive_created && (
              <div className="alert alert-success">
                Review your required profile data in order to be listed.
              </div>
            )}
            {!carInfoSaved() && (
              <>
                <div>
                  <h5 className="mt30">Car Info</h5>
                  <hr className="hr-line" />
                  <div className="mt20">
                    <div className="bubble-container">
                      {isCarImageProcessing && (
                        <ReactLoading
                          type="bubbles"
                          color="#3399ff"
                          height="100px"
                          width="100px"
                        />
                      )}
                    </div>
                    {hasCarImage() ? (
                      <div className="imgWrapper carImgWrapper">
                        <img
                          src={displayImage('car')}
                          className="responsive-img uploadPic"
                          alt=""
                        />
                      </div>
                    ) : (
                      <Dropzone
                        onDrop={files => uploadImage(files, 'car')}
                        onFileDialogCancel={onCancel}
                        className="dropzone">
                        {({getRootProps, getInputProps}) => (
                          <section className="dropzone">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div>
                                Try dropping car image here, or click to select
                                image to upload. Size should be less than 3 MB.
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )}
                    <span className="error">
                      {errorMessageFor('has_car_image')}
                    </span>
                  </div>
                  {!originalyHasCarBasicInfo() && (
                    <div>
                      <div className="row">
                        <div className="col l6 m6 s12">
                          <FormControl className="selectField">
                            <InputLabel htmlFor="select-multiple">
                              Make*{' '}
                            </InputLabel>
                            <Select
                              value={profile.car_make || ''}
                              onChange={event =>
                                onFieldChange('car_make', event)
                              }
                              input={<Input id="select-multiple" />}
                              MenuProps={MenuProps}
                              className="selected-menu-field">
                              {_.map(carMakeList, (make, index) => {
                                return (
                                  <MenuItem
                                    key={`make-${index}`}
                                    value={make.car_make}>
                                    {make.car_make}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                            <span className="error">
                              {errorMessageFor('car_make')}
                            </span>
                          </FormControl>
                        </div>
                        <div className="col l6 m6 s12">
                          <FormControl className="selectField">
                            <InputLabel htmlFor="select-multiple">
                              Model*
                            </InputLabel>
                            <Select
                              value={profile.car_model || ''}
                              onChange={event =>
                                onFieldChange('car_model', event)
                              }
                              input={<Input id="select-multiple" />}
                              MenuProps={MenuProps}
                              className="selected-menu-field">
                              {carModelList().map((model, index) => (
                                <MenuItem
                                  key={`model-${index}`}
                                  value={model.value}>
                                  {model.value}
                                </MenuItem>
                              ))}
                            </Select>
                            <span className="error">
                              {errorMessageFor('car_model')}
                            </span>
                          </FormControl>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col l6 m6 s12">
                          <FormControl className="selectField">
                            <InputLabel htmlFor="select-multiple">
                              Year*
                            </InputLabel>
                            <Select
                              value={profile.car_year || ''}
                              onChange={event =>
                                onFieldChange('car_year', event)
                              }
                              input={<Input id="select-multiple" />}
                              MenuProps={MenuProps}
                              className="selected-menu-field">
                              {carYearList().map((year, index) => (
                                <MenuItem
                                  key={`year-${index}`}
                                  value={year.value}>
                                  {year.value}
                                </MenuItem>
                              ))}
                            </Select>
                            <span className="error">
                              {errorMessageFor('car_year')}
                            </span>
                          </FormControl>
                        </div>
                        <div className="col l6 m6 s12">
                          <FormControl className="selectField">
                            <InputLabel htmlFor="select-multiple">
                              Color*
                            </InputLabel>
                            <Select
                              value={profile.car_color || ''}
                              onChange={event =>
                                onFieldChange('car_color', event)
                              }
                              input={<Input id="select-multiple" />}
                              MenuProps={MenuProps}
                              className="selected-menu-field">
                              {carColor.map((color, index) => (
                                <MenuItem key={index} value={color}>
                                  {color}
                                </MenuItem>
                              ))}
                            </Select>
                            <span className="error">
                              {errorMessageFor('car_color')}
                            </span>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt40 mb20">
                  <PrimaryButton
                    color="primary"
                    buttonName={
                      isPayoutProcessing ? 'Please Wait...' : 'Update Profile'
                    }
                    className="lg-primary"
                    disabled={isProcessing}
                    handleButtonClick={() => handleProfileSave()}
                  />
                </div>
              </>
            )}
            {!has_payout_details && price > 0 && (
              <div className="row mt20 ml0">
                <ProfilePayoutSection />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileChecklist
