import missingImg from 'images/missing.png'

const ChatUser = ({user, isActive, onLoadChat}) => (
  <li
    className={`user ${isActive ? 'active' : ''}`}
    onClick={() => onLoadChat(user.id)}>
    <div
      className={`user-link clearfix ${user.attributes.unread > 0 &&
        'unread-msg-block'}`}>
      <div className="img-container">
        <img
          src={
            user.attributes.first_name
              ? user.attributes.small_image_url || missingImg
              : missingImg
          }
          className="responsive-img"
          alt=""
        />
      </div>
      <div className="friend-name">
        <strong>
          {user.attributes.first_name
            ? `${user.attributes.first_name} ${user.attributes.last_name}`
            : 'Deleted User'}
        </strong>
      </div>
      <div
        className={`last-message ${user.attributes.unread > 0 &&
          'unread-color'}`}>
        {user.attributes.last_message}
      </div>
      <small className={`time ${user.attributes.unread > 0 && 'unread-color'}`}>
        {user.attributes.last_message_at} ago
      </small>
      {user.attributes.unread > 0 && (
        <small className="unread-msg ">{user.attributes.unread}</small>
      )}
      <small className="chat-alert text-muted">
        <i className="fa fa-reply"></i>
      </small>
    </div>
  </li>
)

export default ChatUser
