import {CircularProgress} from '@material-ui/core'
import styles from './CircularLoading.module.scss'

const CircularLoading = ({noPadding = false}) => (
  <div
    className={styles.container}
    style={noPadding ? {paddingTop: '30px'} : {}}>
    <CircularProgress />
  </div>
)

export default CircularLoading
