import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'

import {PrimaryButton} from 'components/Buttons'
import useUserStore from 'store/UserStore'
import {useNavigate} from 'react-router-dom'

const VerifyEmail = props => {
  const navigate = useNavigate()
  const {
    errors: userErrors,
    emailVerified,
    resetUserFlagsRequest,
    verifyOtpRequest,
  } = useUserStore()

  const initial_state = {
    identity: '',
    userErrors: {},
    otp: '',
    isProcessing: false,
  }

  const [state, updateState] = useState(initial_state)

  useEffect(() => {
    if (emailVerified) {
      resetUserFlagsRequest()
      navigate('/login')
    }
  }, [emailVerified, navigate, resetUserFlagsRequest])

  useEffect(() => {
    if (userErrors) {
      updateState(s => ({
        ...s,
        userErrors: userErrors,
        isProcessing: false,
      }))
    }
  }, [userErrors])

  const onFieldChange = (fieldName, event) => {
    updateState(s => ({
      ...s,
      [fieldName]: event.target.value,
      [`${fieldName}userErrors`]: null,
    }))
  }

  const handleVerifyEmail = () => {
    const {otp} = state
    updateState(s => ({
      ...s,
      isProcessing: true,
    }))
    verifyOtpRequest({otp: otp})
  }

  const errorMessageFor = fieldName => {
    if (userErrors && userErrors[fieldName]) {
      return userErrors[fieldName]
    }
  }

  const {otpError} = state
  return (
    <div className="login-container">
      <div className="container">
        <Card className="cardContainer">
          <h3 className="center-align">Verify Email</h3>
          <div className="subHeading">
            Please enter a valid verification code that you've received on your
            registered email.
          </div>
          <TextField
            fullWidth
            className="text-field"
            id="email"
            type="text"
            label="Enter Verification Code"
            margin="normal"
            onChange={event => onFieldChange('otp', event)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleVerifyEmail()
              }
            }}
          />
          <span className="error">{otpError}</span>
          <span className="error">{errorMessageFor('email')}</span>
          <div className="mt40">
            <PrimaryButton
              color="primary"
              buttonName={
                state.isProcessing ? 'Please Wait...' : 'Verify Email'
              }
              disabled={!!state.isProcessing}
              className="leftIcon-btn pswrd-btn"
              handleButtonClick={() => handleVerifyEmail()}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default VerifyEmail
