import React, {useState} from 'react'
import {Button, Input} from 'react-chat-elements'
import styles from './Chats.module.scss'
import MessageList from 'components/Chats/MessageList'

import 'react-chat-elements/dist/main.css'
import {useUser} from 'context/UserProvider'

const Chats = ({
  messages = [],
  isDeletedUser = false,
  onSend,
  onFocus,
  onBlur,
}) => {
  const {user: currentUser} = useUser()
  const [message, setMessage] = useState('')

  const data = messages.map(message => ({
    id: message.id,
    position: message?.user?.id === currentUser?.id ? 'right' : 'left',
    text: message.text,
    date: new Date(message.createdAt).toLocaleString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    time: new Date(message.createdAt).toLocaleString('en-us', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }),
    createdAt: message.createdAt,
    avatar: message?.user?.id !== currentUser?.id ? message?.user?.avatar : '',
    user_id: message?.user?.id,
  }))

  const sendMessage = () => {
    if (message.trim().length === 0) return
    onSend(message.trim())
    setMessage('')
  }

  return (
    <>
      <div className={['chat-block', styles.container].join(' ')}>
        <MessageList messages={data} />
      </div>
      {!isDeletedUser && (
        <Input
          placeholder="Type a message..."
          multiline={false}
          value={message}
          inputStyle={{border: 'none', boxShadow: 'none'}}
          className={styles.chatInput}
          onFocus={onFocus}
          onBlur={onBlur}
          rightButtons={
            message !== '' && (
              <Button
                text="Send"
                onClick={sendMessage}
                className="chat-send-btn"
              />
            )
          }
          onChange={event => setMessage(event.target.value)}
          onKeyPress={e => {
            e.key === 'Enter' && sendMessage()
          }}
        />
      )}
    </>
  )
}

export default Chats
