import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import moment from 'moment'
import TripMenu from 'components/TripMenu/TripMenu'
import missingImg from 'images/missing.png'

import styles from './TripCard.module.scss'

const TripCard = ({
  trip,
  render_status,
  render_menu,
  onMouseEnter,
  onMouseLeave,
  isSearch,
}) => {
  const navigate = useNavigate()

  const initial_state = {
    render_status: render_status || false,
    render_menu: render_menu || false,
    on_click_active: true,
    trip_cancel_sent: false,
    request_cancel_sent: false,
  }

  const [state, setState] = useState(initial_state)

  const renderStatus = (trip_status) => {
    const options = {
      active: {text: "It's a go", style: 'active-label'},
      expired: {text: 'Expired', style: 'exp-label'},
      cancelled: {text: 'Cancelled', style: 'cancel-label'},
    }
    // if (!props.render_status || typeof trip_status === 'undefined') return ''

    let stat = options['active']
    if (trip_status?.is_expired) stat = options['expired']
    if (trip_status?.is_cancelled) stat = options['cancelled']

    return (
      <span
        className={[styles.event, styles[stat.style]].join(
          ' ',
        )}>{`${stat.text}`}</span>
    )
  }

  const renderEvent = () => (
    <span
      className={styles.event}
      style={{
        color:
          trip?.attributes?.drive_type === 'commute' ? '#004085' : '#856404',
        background:
          trip?.attributes?.drive_type === 'commute' ? '#cce5ff' : '#fff3cd',
      }}>
      {trip?.attributes?.drive_type === 'commute' ? 'Commute' : 'Adventure'}
    </span>
  )

  const renderMenu = () => {
    if (!state.render_menu) return ''

    return (
      <TripMenu
        trip={trip}
        on_menu_open={() => {
          setState({...state, on_click_active: false})
        }}
        on_menu_closed={() => {
          setState({...state, on_click_active: true})
        }}
        on_trip_cancelled={(id) => {
          setState({...state, trip_cancel_sent: true, trip_rendered: false})
        }}
        on_request_cancelled={(id) => {
          setState({...state, request_cancel_sent: true, trip_rendered: false})
        }}
      />
    )
  }

  const getImage = (user) => {
    return user?.display_image ? user.display_image : missingImg
  }

  const user = trip?.relationships?.profile?.user?.attributes

  const link_to = {
    pathname: `/ride/${trip?.attributes?.slug || trip.id}`,
    state: {},
  }
  const on_click_cb = (e) => {
    if (
      state.on_click_active &&
      !e.target.className.includes('MuiDialog-container')
    ) {
      navigate(link_to.pathname)
    }
  }

  return (
    <div
      className={isSearch ? styles.searchTripContainer : styles.container}
      onMouseEnter={() => onMouseEnter && onMouseEnter(trip)}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      onClick={on_click_cb}>
      <div className={styles.header}>
        {trip?.attributes?.name}
        {renderMenu()}
      </div>
      <div className={styles.top}>
        <div>
          <img
            src={getImage(user)}
            loading="lazy"
            alt=""
            className={styles.avatar}
          />

          <div className={styles.driverName}>
            {`${
              trip?.relationships?.profile?.user?.attributes?.first_name ||
              'Deleted User'
            }`}
            's Ride
          </div>
        </div>

        <div className={styles.mainInfo}>
          <div className={styles.eventName}>{trip?.attributes?.event_name}</div>
          <div className={[styles.info, styles.mt5].join(' ')}>
            <span className={styles.seatsLabel}>
              Seats Left: {trip?.attributes?.available_seats}
            </span>
            {renderEvent()}
          </div>

          <div className={[styles.info, styles.mt5].join(' ')}>
            <div className={styles.info}>
              <StarRatingComponent
                name="average_rating"
                starCount={5}
                value={user?.average_rating || 0}
                editing={false}
              />
              <span>({user?.rating_count})</span>
            </div>

            {!isSearch && renderStatus(trip?.attributes)}
          </div>

          <div className={styles.tripInfo}>
            <div className={styles.tripItem}>
              <div className={styles.tripLabel}>Price</div>
              <div className={styles.tripValue}>${trip?.attributes?.price}</div>
            </div>

            <div className={styles.tripItem}>
              <div className={styles.tripLabel}>Miles</div>
              <div className={styles.tripValue}>
                {trip?.attributes?.total_distance}
              </div>
            </div>

            <div className={styles.tripItem}>
              <div className={styles.tripLabel}>Duration</div>
              <div className={styles.tripValue}>
                {trip?.attributes?.drive_type === 'adventure' &&
                !trip?.attributes?.start_date
                  ? '-'
                  : trip?.attributes?.modified_duration}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div>
          <div>
            <i className="fa fa-map-marker icon" />{' '}
            {trip?.attributes?.modified_start_location}
          </div>
          <div className="time-text">
            {trip?.attributes?.start_date
              ? moment(trip?.attributes?.start_date).format('MM/DD/YYYY')
              : ''}
          </div>
        </div>

        <i className="fa fa-long-arrow-right separator-icon"></i>

        <div>
          <div>
            <i className="fa fa-map-marker icon" />{' '}
            {trip?.attributes?.modified_destination}
          </div>
          <div className="time-text">
            {moment(trip?.attributes?.finish_date).format('MM/DD/YYYY')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TripCard
