import {Link} from 'react-router-dom'
import missingImg from 'images/missing.png'

import styles from './MessageList.module.scss'

const SendMessageBox = ({message, isFirst, isLast, avatar, showAvatar}) => (
  <div className={`${styles.message} ${styles.messageSend}`}>
    <div
      className={`${styles.content} ${styles.contentSend} ${isFirst &&
        styles.firstMessageSend} ${isLast && styles.lastMessageSend}`}>
      <div className={styles.textSend}>{message.text}</div>
      <div className={styles.timeSend}>{message.time}</div>
    </div>

    <Link
      to="/my_profile"
      className="user-img-container"
      style={{visibility: showAvatar ? 'visible' : 'hidden'}}>
      <img
        alt=""
        draggable="false"
        src={avatar || missingImg}
        className={styles.avatar}
      />
    </Link>
  </div>
)

export default SendMessageBox
