import UserProvider from './context/UserProvider'
import SharedContainer from 'containers'

const App = () => (
  <UserProvider>
    <SharedContainer />
  </UserProvider>
)

export default App
