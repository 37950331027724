import React from 'react'

const Terms = () => (
  <div className="aboutUs-page">
    <div className="container">
      <h3 className="center-align">TERMS OF SERVICE</h3>
      <div className="content">
        WELCOME TO Ridesurfing, Inc. We hope you find it useful.
      </div>
      <h5 className="center-align">
        TERMS AND CONDITIONS OF USE (“TERMS OF USE”)
      </h5>
      <div className="content">
        Please review the following Terms of Use and accept them in the place or
        on the terms stated.
        <br />
        <br />
        These Terms and Conditions, as may be amended from time to time, apply
        to all our services directly or indirectly provided (through third
        parties), made available online, through any mobile device, by email or
        telephone. By accessing, browsing and using our website (including
        mobile) or any of our applications through whatever platform
        (collectively, “website”) or by completing a reservation, you
        acknowledge and agree to have read, understood and agreed to the Terms
        and Conditions, including the Privacy Statement, set forth below and
        acknowledge that they apply to you. The services offered in or by this
        Terms of Use Agreement are provided for your personal, non-commercial
        use. By accessing our servers, websites, or content therefrom (together,
        "Ridesurfing"), you accept and agree to these Terms of Use ("TOU"), last
        updated March 3, 2017.
      </div>
      <div className="mt20 mb30">
        <h6>LICENSE</h6>
        <div className="content">
          If you are 18 or older, Ridesurfing grants you a limited, revocable,
          nonexclusive, nonassignable, nonsublicensable license to access
          Ridesurfing in compliance with the TOU; unlicensed access is
          unauthorized. You agree not to license, distribute, make derivative
          works, display, sell, or "frame" content from Ridesurfing, excluding
          content you create and sharing with friends/family. You grant us a
          perpetual, irrevocable, unlimited, worldwide, fully paid/
          sublicensable license to use, copy, perform, display, distribute, and
          make derivative works from content you post.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Personal safety</h6>
        <div className="content">
          YOUR SAFETY AND SECURITY ARE VERY IMPORTANT TO US. THE NATURE OF THIS
          WEBSITE PROMOTES THE SHARING OF PERSONAL INFORMATION BY USERS WITH
          OTHER USERS. Ridesurfing CANNOT AND DOES NOT ASSURE THAT IT IS SAFE
          FOR YOU TO HAVE DIRECT CONTACT WITH OTHER USERS OF THIS WEBSITE.
          <br />
          <br />
          Current technological developments make it possible for users of the
          Internet to obtain personal information about, and locate, other
          users, with very little other information. For example, it is possible
          to use certain widely available commercial Internet search engines to
          locate a person’s home solely using that person’s correct name. If you
          believe that any user of this Website is harassing you or is otherwise
          using personal information about you for unlawful purposes, we
          encourage you to first inform local law enforcement authorities and
          then to contact us via The Feedback Form so that we may take
          appropriate action to block further use of the Website by any user who
          is using this Website and information obtained from it for improper
          purposes.
          <br />
          <br />
          Ridesurfing STRONGLY ADVISES YOU TO USE EXTREME CAUTION BEFORE SHARING
          PERSONALLY IDENTIFIABLE INFORMATION WITH OTHER USERS OFTHIS WEBSITE.
          Ridesurfing DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON OR
          SCREENINGS OF ITS USERS, USER PROVIDED DATA AND ANY INFORMATION
          PROVIDED BY THIRD PARTY API’S (FACEBOOK, GOOGLE PLUS ETC…).
          Ridesurfing ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS MEMBERS
          (i.e. USERS AND PROVIDERS OF RIDES AND SERVICES) OR ATTEMPT TO VERIFY
          THE STATEMENTS OF ITS MEMBERS, MEMBER-PROVIDED DATA AND ANY
          INFORMATION PROVIDED BY THIRD PARTY API’S (FACEBOOK, GOOGLE PLUS
          ETC...) BUT RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND
          CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES), AT
          ANY TIME AND USING AVAILABLE PUBLIC RECORDS. YOUR ACCEPTANCE OF THESE
          TERMS OF USE INCLUDES YOUR CONSENT TO SUCH BACKGROUND CHECK. YOU
          REPRESENT THAT YOU HAVE NEVER BEEN CONVICTED OF A FELONY AND THAT YOU
          ARE NOT REQUIRED TO REGISTER AS A SEX OFFENDER WITH ANY GOVERNMENT
          ENTITY.
          <br />
          <br />
          By accessing this Website, you agree to use any personal information
          provided to you by other users of this Website in a lawful and
          responsible manner. You further agree that you will not use personal
          information about other users of this Website for any reason without
          the express prior consent of the user that has provided such
          information to you.
          <br />
          <br />
          IF YOU ARE UNDER 18 YEARS OF AGE BUT HAVE IMPROPERLY ACCESSED THIS
          WEBSITE BY PROVIDING FALSE INFORMATION TO US, NOT ONLY ARE YOU AN
          UNAUTHORIZED USER USING THIS WEBSITE IN VIOLATION OF THESE TERMS OF
          USE, BUT YOU MAY ALSO BE PUTTING YOURSELF AND OTHERS IN DANGER. YOU
          AGREE THAT Ridesurfing IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER
          OF THE WEBSITE AND IS NOT LIABLE (DIRECTLY OR INDIRECTLY) FOR ANY
          LOSSES OR DAMAGES WHATSOEVER ARISING OUT OF OR RELATING TO THE CONDUCT
          OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE WEBSITE.
          <br />
          <br />
          <b>
            <u>USE.</u>
          </b>
          You agree not to use or provide software (except for general purpose
          web browsers and email clients, or software expressly licensed by us)
          or services that interact or interoperate with Ridesurfing, e.g. for
          downloading, uploading, posting, flagging, emailing, search, or mobile
          use. Robots, spiders, scripts, scrapers, crawlers, etc. are
          prohibited, as are misleading, unsolicited, unlawful, and/or spam
          postings/email. You agree not to collect users personal and/or contact
          information ("PI") unless otherwise provided by such user.
          <br />
          <br />
          <b>
            <u>MODERATION.</u>
          </b>
          You agree we may moderate Ridesurfing access and use in our sole
          discretion, e.g. by blocking (e.g. IP addresses), filtering, deletion,
          delay, omission, verification, and/or access/account/license
          termination. You agree (1) not to bypass said moderation, (2) we are
          not liable for moderating, not moderating, or representations as to
          moderating, and (3) nothing we say or do waives our right to moderate,
          or not
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>ELIGIBILITY</h6>
        <div className="content">
          The Site, Application and Services are intended solely for persons who
          are 18 or older. Any access to or use of the Site, Application or
          Services by anyone under 18 is expressly prohibited. By accessing or
          using the Site, Application or Services you represent and warrant that
          you are 18 or older.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>How the Site, Application and Services Work</h6>
        <div className="content">
          The Site, Application and Services are intended to be used and can be
          used for ride-sharing; that is, to facilitate the posting and booking
          of rides for drivers and passengers to join each other in a designated
          vehicle and travel together on various trips, commutes or excursions
          of limited duration to the confirmed final destination stated on the
          posting (“Ride(s)”). Available Rides are included in Postings (i.e. an
          electronic mail message sent to a bulletin board, website, etc, and
          intended for access by every user) (“Posting(s)”) on the Site,
          Application and Services by Drivers. You may view Postings as an
          unregistered visitor to the Site, Application and Services; however,
          if you wish to book a Ride or create a Posting, you must first
          register to create a Ridesurfing Account (defined below)
          <br />
          <br />
          As stated above, Ridesurfing makes available an online platform or
          marketplace with related technology for Ridesurfers (Passengers) and
          Drivers to meet online and arrange for bookings of Rides directly with
          each other. Ridesurfing is not an owner or operator of vehicles,
          including, but not limited to, cars, trucks, motorcycles, tractor
          trailers (SemiTrucks), boats, planes, other means of transportation or
          Rides, nor is it a provider of Accommodations of any kind, including,
          but not limited to, cars, trucks, motorcycles, tractor trailers
          (SemiTrucks), boats, planes, other means of transportation or
          Accommodations and Ridesurfing does not own, sell, resell, provide,
          lease, manage and/or control Accommodations, including, but not
          limited to, cars, trucks, motorcycles, tractor trailers (SemiTrucks),
          boats, planes, other means of transportation or travel services.
          UNLESS EXPLICITLY SPECIFIED OTHERWISE IN THE Ridesurfing PLATFORM,
          Ridesurfing's RESPONSIBILITIES ARE LIMITED TO: (I) FACILITATING THE
          AVAILABILITY OF THE SITE, APPLICATION AND SERVICES AND (II) SERVING AS
          THE LIMITED PAYMENT COLLECTION AGENT OF EACH DRIVER FOR THE PURPOSE OF
          ACCEPTING PAYMENTS FROM Ridesurfers ON BEHALF OF THE DRIVER.
          <br />
          <br />
          PLEASE NOTE THAT, AS STATED ABOVE, THE SITE, APPLICATION AND SERVICES
          ARE INTENDED TO BE USED TO FACILITATE DRIVERS AND Ridesurfers
          CONNECTING AND BOOKING RIDES DIRECTLY WITH EACH OTHER. Ridesurfing
          CANNOT AND DOES NOT CONTROL THE CONTENT CONTAINED IN ANY POSTINGS AND
          THE CONDITION, LEGALITY OR SUITABILITY OF ANY RIDES. Ridesurfing IS
          NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TOANY
          AND ALL POSTINGS AND RIDES. ACCORDINGLY, ANY BOOKINGS WILL BE MADE OR
          ACCEPTED AT THE MEMBER'S OWN RISK.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Account Registration</h6>
        <div className="content">
          In order to access certain features of the Site and Application, and
          to book an Accommodation or create a Posting, you must register to
          create an account ("Ridesurfing Account") and become a Member. You may
          register to join the Services directly via the Site or Application or
          as described in this section.
          <br />
          <br />
          You can also register to join by logging into your account with
          certain third-party social networking sites ("SNS") (including, but
          not limited to, Facebook; each such account, a "Third-Party Account"),
          via our Site or Application, as described below. As part of the
          functionality of the Site, Application and Services, you may link your
          Ridesurfing Account with Third-Party Accounts, by either: (i)
          providing your Third- Party Account login information to Ridesurfing
          through the Site, Services or Application; or (ii) allowing
          Ridesurfing to access your Third-Party Account, as permitted under the
          applicable terms and conditions that govern your use of each
          Third-Party Account. You represent that you are entitled to disclose
          your Third-Party Account login information to Ridesurfing and/or grant
          Ridesurfing access to your Third-Party Account (including, but not
          limited to, for use for the purposes described herein), without breach
          by you of any of the terms and conditions that govern your use of the
          applicable Third-Party Account and without obligating Ridesurfing to
          pay any fees or making Ridesurfing subject to any usage limitations
          imposed by such third-party service providers. By granting Ridesurfing
          access to any Third-Party Accounts, you understand that Ridesurfing
          will access, make available and store (if applicable) any Content that
          you have provided to and stored in your Third-Party Account ("SNS
          Content") so that it is available on and through the Site, Services
          and Application via your Ridesurfing Account and Ridesurfing Account
          profile page. Unless otherwise specified in these Terms, all SNS
          Content, if any, will be considered to be Member Content for all
          purposes of these Terms. Depending on the Third-Party Accounts you
          choose and subject to the privacy settings that you have set in such
          Third-Party Accounts, personally identifiable information that you
          post to your Third-Party Accounts will be available on and through
          your Ridesurfing Account on the Site, Services and Application. Please
          note that if a Third-Party Account or associated service becomes
          unavailable or Ridesurfing’s access to such Third-Party Account is
          terminated by the third-party service provider, then SNS Content will
          no longer be available on and through the Site, Services and
          Application.
          <br />
          <br />
          <b>
            PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD- PARTY SERVICE
            PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
            SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
            Ridesurfing MAKES NO EFFORT TO REVIEW ANY SNS CONTENT FOR ANY
            PURPOSE, INCLUDING BUT NOT LIMITED TO FOR ACCURACY, LEGALITY,
            VETTING OR NON-INFRINGEMENT AND Ridesurfing IS NOT RESPONSIBLE FOR
            ANY SNS CONTENT.
          </b>
          <br />
          <br />
          Your Ridesurfing Account and your Ridesurfing Account profile page
          will be created for your use of the Site and Application based upon
          the personal information you provide to us or that we obtain via an
          SNS as described above. You may not have more than one (1) active
          Ridesurfing Account. You agree to provide accurate, current and
          complete information during the registration process and to update
          such information to keep it accurate, current and complete.
          Ridesurfing reserves the right to suspend or terminate your
          Ridesurfing Account and your access to the Site, Application and
          Services if you create more than one (1) Ridesurfing Account, or if
          any information provided during the registration process or thereafter
          proves to be inaccurate, fraudulent, not current or incomplete. You
          are responsible for safeguarding your password. You agree that you
          will not disclose your password to any third party and that you will
          take sole responsibility for any activities or actions under your
          Ridesurfing Account, whether or not you have authorized such
          activities or actions. You will immediately notify Ridesurfing of any
          unauthorized use of your Ridesurfing Account.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Ride Postings</h6>
        <div className="content">
          As a Member, you may create Postings. To create a Posting, you will be
          asked a variety of questions about the Ride to be listed, including,
          but not limited to, the start and ending locations of travel,
          capacity, expected travel time, features, and availability of the Ride
          and pricing and related rules and financial terms. In order to be
          featured in Postings via the Site, Application and Services. Postings
          will be made publicly available via the Site, Application and
          Services. Other Members will be able to book your Ride via the Site,
          Application and Services based upon the information provided in your
          Posting. You understand and agree that once a Ridesurfer requests a
          booking of your Ride, you may not request the Ridesurfer to pay a
          higher price than in the booking request.
          <br />
          <br />
          You acknowledge and agree that you alone are responsible for any and
          all Postings and Member Content you post. Accordingly, you represent
          and warrant that any Posting you post and the booking of, or a
          Ridesurfer’s commute to the listed destination on the post (i) will
          not breach any agreements you have entered into with any third
          parties, such as a lease or rental agreements, and (ii) will (a) be in
          compliance with all applicable laws (such as vehicle insurance laws
          and laws governing rental vehicles), Tax requirements, and rules and
          regulations that may apply to any Ride included in a Posting you post
          (including having all required permits, licenses and registrations),
          and (b) not conflict with the rights of third parties. Please note
          that Ridesurfing assumes no responsibility for a Driver’s compliance
          with any agreements with or duties to third parties, applicable laws,
          rules and regulations. Ridesurfing reserves the right, at any time and
          without prior notice, to remove or disable access to any Posting for
          any reason, including Postings that Ridesurfing, in its sole
          discretion, considers to be objectionable for any reason, in violation
          of these Terms or Ridesurfing’s then-current Policies and Community
          Guidelines, or otherwise harmful to the Site, Application or Services.
          <br />
          <br />
          If you are a Driver, you understand and agree that Ridesurfing does
          not act as an insurer or as your contracting agent. If a Ridesurfer
          requests a booking of your Ride and travels using your Ride, any
          agreement you enter into with such Ridesurfer is between you and the
          Ridesurfer and Ridesurfing is not a party to it. Notwithstanding the
          foregoing, Ridesurfing Payment Processing third party vendors serve as
          the limited authorized payment collection agent of the Driver for the
          purpose of accepting, on behalf of the Driver, payments from
          Ridesurfers of such amounts stipulated by the Driver (including
          transportation fees and/or Taxes).
          <br />
          <br />
          When you create a Posting, you may also choose to include certain
          requirements which must be met by the Members who are eligible to
          request a booking of your Ride, such as requiring Members to have a
          profile picture, verified phone number or meet specific travel
          preferences such as non- smoking, no pets allowed or kid friendly, in
          order to book your Ride. Any Member wishing to book Ride included in
          Postings with such requirements must meet these requirements.
          <br />
          <br />
          If you are a Driver, Ridesurfing makes certain tools available to you
          to help you to make informed decisions about which Members you choose
          to confirm or preapprove for booking for your Ride. You acknowledge
          and agree that, as a Driver, you are responsible for your own acts and
          omissions and are also responsible for the acts and omissions of any
          individuals you travel with or are otherwise present during the Ride
          at your request or invitation, excluding the Ridesurfer (and the
          individuals the Ridesurfer invites to the Ride, if applicable)
          <br />
          <br />
          Ridesurfing recommends that Drivers obtain appropriate insurance for
          their Rides. Please review any insurance policy that you may have for
          your Ride carefully, and in particular please make sure that you are
          familiar with and understand any exclusions to, and any deductibles
          that may apply for, such insurance policy, including, but not limited
          to, whether or not your insurance policy will cover the actions or
          inactions of Ridesurfers (and if the Ridesurfer is covered on your
          auto insurance if the Ridesurfer helps with driving, if applicable)
          while on travel with you.
          <br />
          <br />
          You agree that you alone are responsible for ensuring that your
          profile and vehicle images on your Posting are accurately represented.
          You warrant that you will cease to use the images if such images cease
          to accurately represent your Posting. You agree that Ridesurfing may
          use these images for advertising, marketing, commercial and other
          business purposes in any media or platform, whether in relation to
          your Posting or otherwise, without further notice or compensation.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>No Endorsement</h6>
        <div className="content">
          Ridesurfing does not endorse any Member or any Ride. Members are
          required by these Terms to provide accurate information, and although
          Ridesurfing may undertake additional checks and processes designed to
          help verify or check the identities or backgrounds of users, we do not
          make any representations about, confirm, or endorse any Member or the
          Member's purported identity or background.
          <br />
          <br />
          Any references in the Site, Application or Services to a Member being
          "verified" or "connected" (or similar language) only indicate that the
          Member has completed a relevant verification process, and does not
          represent anything else. Any such description is not an endorsement,
          certification or guarantee by Ridesurfing about any Member, including
          of the Member's identity and whether the Member is trustworthy, safe
          or suitable. Instead, any such description is intended to be useful
          information for you to evaluate when you make your own decisions about
          the identity and suitability of others whom you contact or interact
          with via the Site, Application and Services. We therefore recommend
          that you always exercise due diligence and care when deciding whether
          to travel with a Driver or to accept a booking request from a
          Ridesurfer, or to have any other interaction with any other Member.
          Ridesurfing is not responsible for any damage or harm resulting from
          your interactions with other Members.
          <br />
          <br />
          By using the Site, Application or Services, you agree that any legal
          remedy or liability that you seek to obtain for actions or omissions
          of other Members or other third parties will be limited to a claim
          against the particular Members or other third parties who caused you
          harm. You agree not to attempt to impose liability on or seek any
          legal remedy from Ridesurfing with respect to such actions or
          omissions. Accordingly, we encourage you to communicate directly with
          other Members on the Site and Services regarding any bookings or
          Postings made by you. This limitation shall not apply to any claim by
          a Driver against Ridesurfing regarding the remittance of payments
          received from a Ridesurfer by Ridesurfing on behalf of a Driver, which
          instead shall be subject to the limitations described in the section
          below entitled "Limitation of Liability".
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Bookings and Financial Terms</h6>
        <div className="content">
          <b>
            <u>Key definitions</u>
          </b>
          <br />
          <br />
          "Ride Fees" means the amounts that are due and payable by a Ridesurfer
          in exchange for that Ridesurfer’s travel in such Ride. The Driver
          alone, and not Ridesurfing, is responsible for the Ride Fees for his
          or her Posting. The Driver may in his or her sole discretion decide to
          include in these amounts (i) Taxes that the Driver determines that he
          or she has to collect.
          <br />
          "Ridesurfer Fees" means the fee that Ridesurfing charges a Ridesurfer
          for the use of the Services, which is calculated as a percentage of
          the applicable Ride Fees. The Ridesurfer Fees will be displayed to the
          Ridesurfer when the Ridesurfer is asked whether to send a booking
          request to a Driver.
          <br />
          "Driver Fees" means the fee that Ridesurfing charges a Driver for the
          use of the Services, which is calculated as a percentage of the
          applicable Ride Fees. The Driver Fees will be displayed to the Driver
          when the Driver is asked whether to confirm or reject a booking
          request from a prospective Ridesurfer.
          <br />
          "Services" means the Ride, Driver, other Ridesurfers, and any other
          information or materials provided by Ridesurfing separate from and in
          addition to the online marketplace and platform and the access, use
          and benefits of Ridesurfing website.
          <br />
          "Service Fees" means collectively the Ridesurfer Fees and the Driver
          Fees.
          <br />
          "Total Fees" means collectively the Ride Fees and the Ridesurfer Fees
          plus any Taxes.
          <br />
          <br />
          <b>
            <u>Bookings and Financial Terms for Drivers</u>
          </b>
          <br />
          <br />
          If you are a Driver and a booking is requested for your Ride via the
          Site, Application or Services, you will be required to either confirm
          or reject the booking request within the Booking Request Period,
          otherwise the booking request will automatically expire. When a
          booking is requested via the Site, Application or Services, we will
          share with you (i) the first and last name of the Ridesurfer who has
          requested the booking, (ii) a link to the Ridesurfer’s Ridesurfing
          Account profile page, (iii) if the Ridesurfer and Driver have both
          connected their Ridesurfing accounts to SNS, the names of any members
          of an SNS with whom you are "friends" or associated on the SNS if such
          individuals are also "friends" or associated with the Ridesurfer on
          such SNS, and (iv) an indication of whether or not the Ridesurfer has
          provided other information to Ridesurfing, such as a verified email
          address, connection to SNSs, or a government ID. If you are unable to
          confirm or decide to reject a booking request within the Booking
          Request Period, any amount collected by Ridesurfing for the requested
          booking will be refunded to the applicable Ridesurfer’s credit card
          and any pre-authorization of such credit card will be released. When
          you confirm a booking requested by a Ridesurfer, Ridesurfing will send
          you an email, text message or message via the Application confirming
          such booking, depending on the selections you make via the Site,
          Application and Services.
          <br />
          <br />
          Ridesurfing’s third party Payment Processors will collect the Total
          Fees at the time of booking confirmation (i.e. when the Driver
          confirms the booking request) and will initiate payment of the Ride
          Fees (less Ridesurfing’s Driver Fees and any Taxes in respect of the
          Driver Fees, such as VAT in Europe) to the Driver within 24 hours of
          when the Ridesurfer arrives at the applicable Ride (except to the
          extent that a refund is due to the Ridesurfer). The time it takes for
          the Driver to receive payouts may depend upon the payout method chosen
          by the Driver. Some methods involve the use of third-party payment
          processors, who may impose their own additional charges for the use of
          their services on the Driver, including by deducting their charges
          from the payout amount.
          <br />
          <br />
          If you owe or agree to pay any amount via Ridesurfing’s third party
          Payment Processors to Ridesurfing (whether as a result of your
          bookings or actions as a Ridesurfer or otherwise), then Ridesurfing
          Payment Processing third party vendor may (but is not obliged to)
          withhold the amount owing to Ridesurfing from any payout amounts due
          to you as a Driver, and use the withheld amount to setoff the amount
          owed by you to Ridesurfing. If Ridesurfing Payment Processing third
          party vendor does so, then your obligation to pay Breeae will be
          extinguished to the extent of the amount withheld by Ridesurfing’s
          third party Payment Processors and Ridesurfing will cease to owe to
          you any obligations (including, but not limited to, any obligation to
          pay you) with respect to the amount withheld. In addition to the
          amount due, if your account is delinquent or you otherwise have
          chargebacks on your account, you may be charged fees that are
          incidental to our collection of these delinquent amounts and
          chargebacks. Such fees or charges may include collection fees,
          convenience fees, or other third party charges. You hereby explicitly
          agree that all communication in relation to delinquent accounts will
          be made by electronic mail or by phone, as provided to Ridesurfing by
          you. Such communication may be made by Ridesurfing or by anyone on its
          behalf, including but not limited to a third party collection agent.
        </div>
        <h6>
          Appointment of Ridesurfing’s third party Payment Processors as Limited
          Payment Collection Agent for Driver
        </h6>
        <div className="content">
          Each Driver hereby appoints Ridesurfing’s third party Payment
          Processors as the Driver’s limited payment collection agent solely for
          the purpose of accepting the Ride Fees from Ridesurfers.
          <br />
          <br />
          Each Driver agrees that payment made by a Ridesurfer through
          Ridesurfing’s third party Payment Processors, shall be considered the
          same as a payment made directly to the Driver, and the Driver will
          make the Ride available to the Ridesurfer in the agreed-upon manner as
          if the Driver has received the Ride Fees. Each Driver agrees that
          Ridesurfing may, in accordance with the cancellation policy selected
          by the Driver and reflected in the relevant Posting, (i) permit the
          Ridesurfer to cancel the booking and (ii) refund (via Ridesurfing’s
          third party Payment Processors) to the Ridesurfer that portion of the
          Ride Fees specified in the applicable cancellation policy. Each Driver
          understands that as Ridesurfing’s third party Payment Processors
          accepts payments from Ridesurfers as the Driver’s limited payment
          collection agent and that Ridesurfing’s third party Payment Processors
          obligation to pay the Driver is subject to and conditional upon
          successful receipt of the associated payments from Ridesurfers.
          Ridesurfing does not guarantee payments to Driver for amounts that
          have not been successfully received by Ridesurfing’s third party
          Payment Processors from Ridesurfers. In accepting appointment as the
          limited authorized agent of the Driver, Ridesurfing’s third party
          Payment Processors assumes no liability for any acts or omissions of
          the Driver.
          <br />
          <br />
          Please note that Ridesurfing does not currently charge fees for the
          creation of Postings. However, you as a Driver acknowledge and agree
          that Ridesurfing reserves the right, in its sole discretion, to charge
          you for and collect fees from you for the creation of Postings. Please
          note that Ridesurfing will provide notice of any Posting fee
          collection via the Site, Application and Services, prior to
          implementing such a Posting fee feature.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Bookings and Financial Terms for Ridesurfers</h6>
        <div className="content">
          The Drivers, not Ridesurfing, are solely responsible for honoring any
          confirmed bookings and making available any Rides reserved through the
          Site, Application and Services. If you, as a Ridesurfer, choose to
          enter into a transaction with a Driver for the booking of an Ride, you
          agree and understand that you will be required to enter into an
          agreement with the Driver and you agree to accept any terms,
          conditions, rules and restrictions associated with such Ride imposed
          by the Driver. You acknowledge and agree that you, and not
          Ridesurfing, will be responsible for performing the obligations of any
          such agreements, that Ridesurfing is not a party to such agreements,
          and that, with the exception of its payment obligations hereunder,
          Ridesurfing disclaims all liability arising from or related to any
          such agreements. You acknowledge and agree that, notwithstanding the
          fact that Ridesurfing is not a party to the agreement between you and
          the Driver, Ridesurfing’s third party Payment Processors acts as the
          Driver’s payment collection agent for the limited purpose of accepting
          payments from you on behalf of the Driver. Upon your payment of the
          Total Fees to Ridesurfing’s third party Payment Processors, your
          payment obligation to the Driver for the Ride Fees is extinguished,
          and Ridesurfing’s third party Payment Processors is responsible for
          remitting the Ride Fees (less the Driver Fees and any Taxes in respect
          of the Driver Fees, such as VAT in Europe), in the manner described in
          these Terms. In the event that Ridesurfing’s third party Payment
          Processors does not remit any such amounts as described in these
          Terms, such Driver will have recourse only against such Ridesurfing
          entity.
          <br />
          <br />
          The Total Fees payable will be displayed to a Ridesurfer before the
          Ridesurfer sends a booking request to a Driver. As noted above, the
          Driver is required to either confirm or reject the booking request
          within the Booking Request Period; otherwise, the requested booking
          will be automatically cancelled. If a requested booking is cancelled
          (i.e. not confirmed by the applicable Driver), any amounts collected
          by Ridesurfing’s third party Payment Processors will be refunded to
          such Ridesurfer, depending on the selections the Ridesurfer makes via
          the Site and Application, and any pre- authorization of such
          Ridesurfer’s credit card will be released, if applicable.
          <br />
          <br />
          You as a Ridesurfer agree to pay Ridesurfing for the Total Fees for
          any booking requested in connection with your Ridesurfing Account if
          such requested bookings are confirmed by the applicable Driver. In
          order to establish a booking pending the applicable Driver’s
          confirmation of your requested booking, you understand and agree that
          Ridesurfing’s third party Payment Processors, on behalf of the Driver,
          reserve the right, in its sole discretion, to (i) obtain a
          pre-authorization via your credit card for the Total Fees or (ii)
          charge your credit card a nominal amount, not to exceed one dollar
          ($1), or a similar sum in the currency in which you are transacting
          (e.g. one euro or one British pound), to verify your credit card. As a
          general rule, Ridesurfing’s third party Payment Processors will
          collect the Total Fees due once Ridesurfing receives confirmation of
          your booking from the applicable Driver; if necessary, Total Fees may
          instead be collected at a later point. Please note that Ridesurfing
          cannot control any fees that may be charged to a Ridesurfer by his or
          her bank related to Ridesurfing’s collection of the Total Fees, and
          Ridesurfing disclaims all liability in this regard.
          <br />
          <br />
          In connection with your requested booking, you will be asked to
          provide customary billing information such as name, billing address
          and credit card information to Ridesurfing’s third-party payment
          processor(s). You agree to pay Ridesurfing for any confirmed bookings
          made in connection with your Ridesurfing Account in accordance with
          these Terms by one of the methods described on the Site or
          Application, e.g. by PayPal or credit card. You hereby authorize the
          collection of such amounts by charging the credit card provided as
          part of requesting the booking, indirectly, via a third-party online
          payment processor or by one of the payment methods described on the
          Site or Application. You also authorize Ridesurfing to charge your
          credit card in the event of damage caused at an Ride as contemplated
          under "Damage to Rides" below and for Security Deposits, if
          applicable. If you are directed to Ridesurfings’ third-party payment
          processor(s), you may be subject to terms and conditions governing use
          of that third party's service and that third party's personal
          information collection practices. Please review such terms and
          conditions and privacy policy before using the services. Once your
          confirmed booking transaction is complete you will receive a
          confirmation email summarizing your confirmed booking.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Security Deposits</h6>
        <div className="content">
          Driver may choose to include security deposits in their Postings
          ("Security Deposits"). Each Posting will describe whether a Security
          Deposit is required for the applicable Ride. If a Security Deposit is
          included in a Posting for a confirmed booking of an Ride, and a Driver
          makes a claim against such Security Deposit, Ridesurfing’s third party
          payment processor(s) will, in its capacity as the payment collection
          agent of the Driver, use its commercially reasonable efforts to charge
          the Ridesurfer’s payment method in the amount of the Security Deposit,
          within a reasonable period of time after the Ridesurfer’s travels from
          the Driver’s Ride. Ridesurfing will also use its commercially
          reasonable efforts to address Driver’s requests and claims related to
          Security Deposits, but Ridesurfing is not responsible for
          administering or accepting any claims by Drivers related to Security
          Deposits, and disclaims any and all liability in this regard.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Service Fees</h6>
        <div className="content">
          In consideration for the use of Ridesurfing’s online marketplace and
          platform, Ridesurfing charges the Service Fees. Where applicable,
          Taxes (such as VAT in Europe) may also be charged in respect of the
          Driver Fees and Ridesurfer Fees. Ridesurfing third-party processor(s)
          deducts the Driver Fees from the Ride Fees before remitting the
          balance to the Driver as described in these Terms. Ridesurfer Fees
          are, as noted above, included in the Total Fees.
          <br />
          <br />
          Balances will be remitted by Ridesurfing’s third-party payment
          processor(s) to Drivers via check, PayPal, direct deposit or other
          payment methods, as described on the Site or via the Application, in
          the Driver’s currency of choice, depending upon the selections the
          Driver makes via the Site, Application and Services. Amounts may be
          rounded up or down as described the "Rounding Off" section below.
          <br />
          <br />
          Please note that Ridesurfing third-party payment processor(s), may
          impose or deduct foreign currency processing costs on or from any
          payments or payouts by Ridesurfing in currencies other than U.S.
          dollars. More information on any such costs or deductions will be
          available via the Site and Application in the About Us Page. Except as
          otherwise provided herein, Service Fees are non-refundable.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>General Booking and Financial Terms</h6>
        <b>Cancellations and Refunds</b>
        <br />
        <br />
        <div className="content">
          If, as a Ridesurfer, you cancel your requested booking before the
          requested booking is confirmed by a Driver, Ridesurfing Payments will
          cancel any pre-authorization to your credit card and/or refund any
          nominal amounts charged to your credit card in connection with the
          requested booking within a commercially reasonable time. If, as a
          Ridesurfer, you wish to cancel a confirmed booking made via the Site,
          Application and Services, either prior to or after arriving at the
          Ride, the cancellation policy of the Driver contained in the
          applicable Posting will apply to such cancellation. Our ability to
          refund the Ride Fees and other amounts charged to you will depend upon
          the terms of the applicable cancellation policy. Details regarding
          refunds and cancellation policies are available via the Site and
          Application in the “About Us” page
          <br />
          <br />
          If a Driver cancels a confirmed booking made via the Site, Services,
          and Application, (i) Ridesurfing’s third-party payment processor(s)
          will refund the Total Fees for such booking to the applicable
          Ridesurfer within a commercially reasonable time of the cancellation
          and (ii) the Ridesurfer will receive an email or other communication
          from Ridesurfing containing alternative Postings and other related
          information. If the Ridesurfer requests a booking from one of the
          alternative Postings and the Driver associated with such alternative
          Posting confirms the Ridesurfer’s requested booking, then the
          Ridesurfer agrees to pay Ridesurfing the Total Fees relating to the
          confirmed booking for the Ride in the alternative Posting, in
          accordance with these Terms. If a Driver cancelled a confirmed booking
          and you, as a Ridesurfer, have not received an email or other
          communication from Ridesurfing, please contact Ridesurfing.
          <br />
          <br />
          If, as a Driver, you cancel a confirmed booking, you agree that
          Ridesurfing may apply penalties or consequences to you or your
          Posting, including (i) publishing an automated review on your Posting
          indicating that a reservation was cancelled, (ii) keeping the calendar
          for your Posting unavailable or blocked for the dates of the cancelled
          booking, or (iii) imposing a cancellation fee (to be withheld from
          your future payouts or charged to the credit card on file in your
          Ridesurfing Account). You will be notified of the situations in which
          a cancellation fee applies before you decide to cancel.
          <br />
          <br />
          In certain circumstances, Ridesurfing may decide, in its sole
          discretion, that it is necessary or desirable to cancel a confirmed
          booking made via the Site, Application and Services. This may be for
          reasons set forth in Ridesurfing’s Extenuating Circumstances policy or
          for any other reason. Ridesurfing third-party payment processors may
          also determine, in its sole discretion, to refund to the Ridesurfer
          part or all of the amounts charged to the Ridesurfer in accordance
          with the Ridesurfer Refund Policy. You agree that Ridesurfing and the
          relevant Ridesurfer or Passenger will not have any liability for such
          cancellations or refunds.
          <br />
          <br />
          If, as a Driver, your Ridesurfer cancels a confirmed booking or
          Ridesurfing decides that it is necessary to cancel a confirmed
          booking, and Ridesurfing issues a refund to the Ridesurfer in
          accordance with the Ridesurfer Refund Policy or other applicable
          cancellation policies, you agree that in the event you have already
          been paid Ridesurfing shall be entitled to recover the amount of any
          such Ridesurfer refund from you, including by subtracting such refund
          amount out from any future Ride Fees due to you.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Recurring Payments</h6>
        <div className="content">
          In some instances, Ridesurfers may be required to make recurring,
          incremental payments toward the Total Fees owed for a confirmed
          booking before beginning his or her Ride (collectively, "Recurring
          Payments"). More information on Recurring Payments will be made
          available via the Site, Application and Services, if applicable. If
          Recurring Payments apply to a confirmed booking, then the Ridesurfer
          authorizes Ridesurfing Payments, on behalf of the Driver, to collect
          the Total Fees and the Driver agrees that such Ridesurfing entity will
          initiate payouts to the Driver, in the increments and at the frequency
          associated with the applicable Recurring Payments, each as identified
          on the Site, Application and Services.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Rounding Off</h6>
        <div className="content">
          Ridesurfing may, in its sole discretion, round up or round down
          amounts that are payable from or to Ridesurfers or Drivers to the
          nearest whole functional base unit in which the currency is
          denominated (e.g. to the nearest dollar, euro or other supported
          currency); for example, Ridesurfing will round up an amount of $101.50
          to $102.00, and $101.49 to $101.00. Some currencies are denominated in
          large numbers. In those cases, Ridesurfing may determine the
          functional base unit in which those currencies are denominated to be
          10, 100 or 1,000 of the currency; the corresponding examples for such
          currencies would be for Ridesurfing to round up an amount of 1,045 up
          to 1,050 and 1,044 down to 1,040, or 35,450 up to 35,500 and 35,449
          down to 35,400, or 837,500 up to 838,000 and 837,499 down to 837,000.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Payment Processing Errors</h6>
        <div className="content">
          We will take steps to rectify any payment processing errors that we
          become aware of. These steps may include crediting or debiting (as
          appropriate) the same payment method used for the original payout to
          or payment by you, so that you end up receiving or paying the correct
          amount.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Driver Taxes</h6>
        <div className="content">
          You as a Driver understand and agree that you are solely responsible
          for determining (i) your applicable Tax reporting requirements, and
          (ii) the Taxes that should be included, and for including Taxes to be
          collected or obligations relating to applicable Taxes in Postings. You
          are also solely responsible for remitting to the relevant authority
          any Taxes included or received by you. Ridesurfing cannot and does not
          offer Tax-related advice to any Members. Where applicable, or based
          upon request from a Driver, Ridesurfing may issue a valid VAT invoice
          to such Driver.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Damage to the Ride Vehicle</h6>
        <div className="content">
          As a Ridesurfer, you are responsible for leaving the Ride vehicle or
          means of transportation (“Ride Vehicle”) in the condition it was in at
          the beginning of your trip. You acknowledge and agree that, as a
          Ridesurfer, you are responsible for your own acts and omissions. In
          the event that a Driver claims otherwise and provides evidence of
          damage, including but not limited to photographs, you agree to pay the
          cost of the damages. After being notified of the claim and given forty
          eight (48) hours to respond, the payment will be charged to and taken
          from the credit card on file in your Ridesurfing Account. Ridesurfing
          also reserves the right to charge the credit card on file in your
          Ridesurfing Account, or otherwise collect payment from you and pursue
          any avenues available to Ridesurfing in this regard, including using
          Security Deposits, in situations in which you have been determined, in
          Ridesurfing's sole discretion, to have damaged any Ride, including,
          but not limited to, in relation to any payment requests made by
          Drivers under the Ridesurfing Driver Guarantee, and in relation to any
          payments made by Ridesurfing to Drivers. If we are unable to charge
          the credit card on file or otherwise collect payment from you, you
          agree to remit payment for any damage to the Ride to the applicable
          Driver or to Ridesurfing (if applicable). Both Ridesurfers and Drivers
          agree to cooperate with and assist Ridesurfing in good faith, and to
          provide Ridesurfing with such information and take such actions as may
          be reasonably requested by Ridesurfing, in connection with any
          complaints or claims made by Members relating to Rides or any personal
          or other property located at an Ride (including, without limitation,
          payment requests made under the Ridesurfing Driver Guarantee) or with
          respect to any investigation undertaken by Ridesurfing or a
          representative of Ridesurfing regarding use or abuse of the Site,
          Application or the Services. If you are a Ridesurfer, upon
          Ridesurfing's reasonable request, and to the extent you are reasonably
          able to do so, you agree to participate in mediation or similar
          resolution process with a Driver, at no cost to you, which process
          will be conducted by Ridesurfing or a third party selected by
          Ridesurfing or its insurer, with respect to losses for which the
          Driver is requesting payment from Ridesurfing under the Ridesurfing
          Driver Guarantee.
          <br />
          <br />
          If you are a Ridesurfer, you understand and agree that Ridesurfing may
          make a claim under your auto insurance policy related to any damage or
          loss that you may have caused or been responsible for or to an Ride or
          any personal or other property located at an Ride (including without
          limitation amounts paid by Ridesurfing under the Ridesurfing Driver
          Guarantee). You agree to cooperate with and assist Ridesurfing in good
          faith, and to provide Ridesurfing with such information as may be
          reasonably requested by Ridesurfing, in order to make a claim under
          your auto insurance policy, including, but not limited to, executing
          documents and taking such further acts as Ridesurfing may reasonably
          request to assist Ridesurfing in accomplishing the foregoing.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>Holdover: Overstaying without the Driver's Consent</h6>
        <div className="content">
          Ridesurfers agree that a confirmed reservation is merely a license
          granted by the Driver to the Ridesurfer to enter and ride with the
          Driver for a limited duration of the confirmed final destination on
          the posting and in accordance with the Ridesurfer's agreement with the
          Driver. Ridesurfers further agree to leave the Ride no later than the
          checkout time that the Driver specifies in the Posting or such other
          time as mutually agreed upon between the Driver and Ridesurfer. If a
          Ridesurfer stays past the agreed upon confirmation or such other time
          as mutually agreed upon between the Driver and Ridesurfer without the
          Driver's consent, they no longer have a license to stay in the Ride
          and the Driver is entitled to make the Ridesurfer leave. In addition,
          Ridesurfers agree that the Driver can charge the Ridesurfer, for each
          one hour period that the Ridesurfer stays over the agreed period
          without the Ridesurfers consent, an additional trips fee of two times
          the average distance Ride Fee originally paid by the Ridesurfer to
          cover the inconvenience suffered by the Driver, plus all applicable
          Service Fees, Taxes, and any legal expenses incurred by the Driver to
          make the Ridesurfer leave (collectively, "Additional Sums").
          Ridesurfers agree that Ridesurfing third-party payment processor(s),
          in its role as limited collection agent for the Driver, shall charge
          the Ridesurfer's credit card or other payment methods it has on file
          to collect these Additional Sums. In addition, Ridesurfing third-party
          payment processor(s) may recover any costs and expenses it incurs in
          collecting the Additional Sums by charging the Ridesurfer's credit
          card or other payment method on file.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>User Conduct</h6>
        <div className="content">
          You, as a User of this Site and Services offered hereunder, understand
          and agree that you are solely responsible for compliance with any and
          all laws, rules, regulations, and Tax obligations that may apply to
          your use of the Site, Application, Services and Collective Content.
          <br />
          <br />
          In connection with your use of the Site, Application, Services and
          Collective Content, you may not and you agree that you will not:
          violate any local, state, provincial, national, or other law or
          regulation, or any order of a court, including, without limitation,
          and Tax regulations; use manual or automated software, devices,
          scripts, robots, backdoors or other means or processes to access,
          "scrape," "crawl" or "spider" any web pages or other services
          contained in the Site, Application, Services or Collective Content;
          access or use our Site, Application, Services or the Ridesurfing API
          to use, expose, or allow to be used or exposed, any Ridesurfing
          Content: (i) that is not publicly displayed by Ridesurfing in its
          search results pages or posting pages before a booking is confirmed;
          (ii) in any way that is inconsistent with the Ridesurfing Privacy
          Policy or Terms of Service; or (iii) in any way that otherwise
          violates the privacy rights or any other rights of Ridesurfing's users
          or any other third party; use the Site, Application, Services or
          Collective Content for any commercial or other purposes that are not
          expressly permitted by these Terms; copy, store or otherwise access or
          use any information contained on the Site, Application, Services or
          Collective Content for purposes not expressly permitted by these
          Terms; infringe the rights of any person or entity, including without
          limitation, their intellectual property, privacy, publicity or
          contractual rights; interfere with or damage our Site, Application or
          Services, including, without limitation, through the use of viruses,
          cancel bots, Trojan horses, harmful code, flood pings, denial-of-
          service attacks, backdoors, packet or IP spoofing, forged routing or
          electronic mail address information or similar methods or technology;
          use our Site, Application or Services to transmit, distribute, post or
          submit any information concerning any other person or entity,
          including without limitation, photographs of others without their
          permission, personal contact information or credit, debit, calling
          card or account numbers; use our Site, Application, Services or
          Collective Content in connection with the distribution of unsolicited
          commercial email ("spam") or advertisements unrelated to lodging in a
          private residence; "stalk" or harass any other user of our Site,
          Application, Services or Collective Content, or collect or store any
          personally identifiable information about any other user other than
          for purposes of transacting as a Ridesurfing Ridesurfer or Driver;
          offer, as a Driver, any Ride that you do not yourself own or have
          permission to use as a personal vehicle or other property (without
          limiting the foregoing, you will not list Rides as a Driver if you are
          serving in the capacity of a leasing agent or posting agent for a
          third party); register for more than one Ridesurfing Account or
          register for a Ridesurfing Account on behalf of an individual other
          than yourself; unless Ridesurfing explicitly permits otherwise,
          request a booking with an Ride you will not actually be using
          yourself; contact a Driver for any purpose other than asking a
          question related to a booking, such Driver's Rides or Postings;
          contact a Ridesurfer for any purpose other than asking a question
          related to a booking or such Ridesurfer's use of the Site, Application
          and Services; recruit or otherwise solicit any Driver or other Member
          to join third-party services or websites that are competitive to
          Ridesurfing, without Ridesurfing's prior written approval; impersonate
          any person or entity, or falsify or otherwise misrepresent yourself or
          your affiliation with any person or entity; use automated scripts to
          collect information from or otherwise interact with the Site,
          Application, Services or Collective Content; use the Site,
          Application, Services or Collective Content to find a Driver or
          Ridesurfer and then complete a booking of an Ride independent of the
          Site, Application or Services, in order to circumvent the obligation
          to pay any Service Fees related to Ridesurfing's provision of the
          Services or for any other reasons; as a Driver, submit any Posting
          with false or misleading price information, or submit any Posting with
          a price that you do not intend to honor; post, upload, publish, submit
          or transmit any Content that: (i) infringes, misappropriates or
          violates a third party's patent, copyright, trademark, trade secret,
          moral rights or other intellectual property rights, or rights of
          publicity or privacy; (ii) violates, or encourages any conduct that
          would violate, any applicable law or regulation or would give rise to
          civil liability; (iii) is fraudulent, false, misleading or deceptive;
          (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v)
          promotes discrimination, bigotry, racism, hatred, harassment or harm
          against any individual or group; (vi) is violent or threatening or
          promotes violence or actions that are threatening to any other person;
          or (vii) promotes illegal or harmful activities or substances;
          systematically retrieve data or other content from our Site,
          Application or Services to create or compile, directly or indirectly,
          in single or multiple downloads, a collection, compilation, database,
          directory or the like, whether by manual methods, through the use of
          bots, crawlers, or spiders, or otherwise; use, display, mirror or
          frame the Site, Application, Services or Collective Content, or any
          individual element within the Site, Application, Services or
          Collective Content, Ridesurfing's name, any Ridesurfing trademark,
          logo or other proprietary information, or the layout and design of any
          page or form contained on a page in the Site, Application or Services,
          without Ridesurfing's express written consent; access, tamper with, or
          use non-public areas of the Site, Application or Services,
          Ridesurfing's computer systems, or the technical delivery systems of
          Ridesurfing's providers; attempt to probe, scan, or test the
          vulnerability of any Ridesurfing system or network or breach any
          security or authentication measures; avoid, bypass, remove,
          deactivate, impair, descramble, or otherwise circumvent any
          technological measure implemented by Ridesurfing or any of
          Ridesurfing's providers or any other third party (including another
          user) to protect the Site, Services, Application or Collective
          Content; forge any TCP/IP packet header or any part of the header
          information in any email or newsgroup posting, or in any way use the
          Site, Services, Application or Collective Content to send altered,
          deceptive or false source-identifying information; attempt to
          decipher, decompile, disassemble or reverse engineer any of the
          software used to provide the Site, Services, Application or Collective
          Content; or advocate, encourage, or assist any third party in doing
          any of the foregoing.
          <br />
          <br />
          You will not accept or make a payment for Ride Fees outside
          Ridesurfing Payments. If you do so, you acknowledge and agree that
          you: (i) would be in breach of these Terms; (ii) accept all risks and
          responsibility for such payment, and (iii) hold Ridesurfing harmless
          from any liability for such payment. Ridesurfing has the right to
          investigate and prosecute violations of any of the above to the
          fullest extent of the law.
          <br />
          <br />
        </div>
        <br />
        <br />
        <b>
          DISCLAIMER. MANY JURISDICTIONS HAVE LAWS PROTECTING CONSUMERS AND
          OTHER CONTRACT PARTIES, LIMITING THEIR ABILITY TO WAIVE CERTAIN RIGHTS
          AND RESPONSIBILITIES. WE RESPECT SUCH LAWS; NOTHING HEREIN SHALL WAIVE
          OR IS INTENDED TO WAIVE RIGHTS OR RESPONSIBILITIES THAT CANNOT BE
          WAIVED.
        </b>
        <br />
        <br />
        <div className="content">
          To the extent permitted by law, (1) we make no promise as to
          Ridesurfing, including without limitation, its website and operations,
          their completeness, accuracy, availability, timeliness, propriety,
          security or reliability; (2) we make no promise regarding the Driver,
          Ride Vehicle or Ridesurfer (i.e. Rider) or other users or parties
          related to the website; your access and use of the website and Ride
          are at your own risk, and Ridesurfing in all of its manifestations is
          provided "AS IS" and "AS AVAILABLE"; (3) Ridesurfing is not liable for
          any harm resulting from (a) user content; (b) user conduct, e.g.
          illegal conduct; (c) your Ridesurfing use; or (d) our representations;
          (4) WE AND OUR OFFICERS, DIRECTORS, EMPLOYEES ("Ridesurfing
          ENTITIES"), DISCLAIM ALL WARRANTIES & CONDITIONS, EXPRESS OR IMPLIED,
          OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT; (5) Ridesurfing ENTITIES AND PRINCIPALS ARE NOT
          LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          PUNITIVE DAMAGES, OR ANY LOSS (E.G. OF PROFIT, REVENUE, DATA, OR
          GOODWILL); (6) IN NO EVENT SHALL OUR TOTAL LIABILITY EXCEED $100 OR
          WHAT YOU PAID US IN THE PAST YEAR.
        </div>
      </div>
      <div className="mt20 mb30">
        <h6>CLAIMS</h6>
        <div className="content">
          You agree (1) any claim, cause of action or dispute ("Claim") arising
          out of or related to the Terms of Use, or your Ridesurfing use, is
          governed by Arizona ("AZ") law regardless of your location or any
          conflict or choice of law principle; (2) Claims must be resolved
          exclusively by state or federal court in Phoenix, AZ (except we may
          seek injunctive remedy anywhere); (3) to submit to personal
          jurisdiction of said courts; (4) any Claim must be filed by (one) 1
          year after it arose or be forever barred; (5) not to bring or take
          part in a class action against Ridesurfing Entities; (6) (except
          government agencies) to indemnify Ridesurfing Entities for any damage,
          loss, and expense (e.g. legal fees) arising from claims related to
          your Ridesurfing use; (7) you are liable for TOU breaches by
          affiliates (e.g. marketers) paid by you, directly or indirectly (e.g.
          through an affiliate network); and (8) to pay us for breaching or
          inducing others to breach the "USE" section, not as a penalty, but as
          a reasonable estimate of our damages (actual damages are often hard to
          calculate): $0.10 per server request, $1 per post, email, flag, or
          account created, $1 per item of PI collected, and $1000 per software
          distribution, capped at $25,000 per day. MISC. Users complying with
          prior written licenses may access Ridesurfing thereby until
          authorization is terminated. Otherwise, this is the exclusive and
          entire agreement between us. If a TOU term is unenforceable, other
          terms are unaffected. If TOU translations conflict with the English
          version, English controls. See Privacy Policy for how we collect, use
          and share data. IAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS
          (E.G. OF PROFIT, REVENUE, DATA, OR GOODWILL); (6) IN NO EVENT SHALL
          OUR TOTAL LIABILITY EXCEED $100 OR WHAT YOU PAID US IN THE PAST YEAR.
        </div>
      </div>
    </div>
  </div>
)

export default Terms
