import {createContext, useContext, useEffect, useState} from 'react'
import useSessionStore from 'store/SessionStore'

export const UserContext = createContext()

const UserProvider = ({children}) => {
  const {accessToken, getCurrentUserRequest} = useSessionStore()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()

  useEffect(() => {
    if (accessToken) {
      const loadUser = async () => {
        setLoading(true)
        try {
          const user = await getCurrentUserRequest()

          if (user && !user.errors) {
            setUser(user.data)
          }
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      }

      if (!user) {
        loadUser()
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [accessToken, getCurrentUserRequest, user])

  if (loading) return null

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserContext')
  }

  return context
}

export {useUser}

export default UserProvider
