import React, {useEffect} from 'react'
import TripCard from 'components/TripCard/TripCard'
import Pagination from 'components/Pagination'
import useTripStore from 'store/TripStore'
import useTripRequestStore from 'store/TripRequestStore'
import CircularLoading from 'ui/CircularLoading/CircularLoading'

const MyTrips = () => {
  const {
    dataLoaded,
    trips,
    isCancelled: tripCancelled,
    pagination,
    getTripsRequest,
    resetTripFlagRequest,
    resetDataLoadedRequest,
  } = useTripStore()

  const {isCancelled: tripRequestCancelled, resetTripRequestsFlagRequest} =
    useTripRequestStore()

  // useEffect(() => {
  //   var elems = document.querySelectorAll('.clicked-page')
  //   ;[].forEach.call(elems, function(el) {
  //     el.classList.remove('clicked-page')
  //   })
  // }, [])

  useEffect(() => {
    if (tripRequestCancelled) {
      resetTripRequestsFlagRequest()
      getTripsRequest()
    }
  }, [getTripsRequest, resetTripRequestsFlagRequest, tripRequestCancelled])

  useEffect(() => {
    if (tripCancelled) {
      resetTripFlagRequest()
      getTripsRequest()
    }
  }, [getTripsRequest, resetTripFlagRequest, tripCancelled])

  useEffect(() => {
    const loadTrips = async () => {
      await getTripsRequest()
    }

    loadTrips()
  }, [getTripsRequest])

  const onPageNumClick = (e, page) => {
    e.target.parentElement.classList.add('clicked-page')

    resetDataLoadedRequest()
    getTripsRequest(page)
  }

  const renderCard = (trip, trip_idx) => {
    return (
      <TripCard
        trip={{...trip}}
        render_status={true}
        render_menu={true}
        key={trip_idx}
      />
    )
  }

  const renderTrips = () => {
    if (trips.length > 0)
      return trips.map((trip, index) => {
        return renderCard(trip, index)
      })
    return 'You have no trips yet. Consider listing your next ride or tagging along with someone :)'
  }

  return (
    <div className="my-trips">
      <h4>My Rides</h4>

      <div style={{paddingLeft: 15, paddingRight: 15}}>
        <Pagination
          current_page={pagination.current_page}
          per_page={pagination.per_page}
          total_pages={pagination.total_pages}
          total_count={pagination.total_count}
          onPageNumClick={onPageNumClick}
        />
      </div>

      <hr className="mb20" />
      <div
        className="trips-container"
        style={{
          justifyContent: 'flex-start',
        }}>
        {dataLoaded ? renderTrips() : <CircularLoading noPadding />}
      </div>
    </div>
  )
}

export default MyTrips
