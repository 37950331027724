import _ from 'underscore'
import React, {useState, useEffect} from 'react'
import Dropzone from 'react-dropzone'
import Switch from 'react-switch'
import ReactLoading from 'react-loading'
import {useLocation, useNavigate} from 'react-router-dom'
import {useUser} from 'context/UserProvider'
import ProfileCardSection from 'components/ProfileCardSection'
import useSessionStore from 'store/SessionStore'
import missingImg from 'images/missing.png'

const RiderChecklist = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {user: currentUser, setUser} = useUser()
  const {
    profileErrors,
    profileSaved,
    isProcessing,
    resetProfileFlagsRequest,
    setProcessingRequest,
    uploadProfileImageRequest,
  } = useSessionStore()

  const initial_state = {
    profileErrors: {},
    cardErrors: {},
    profile: {
      is_rider: true,
    },
  }

  const [state, setState] = useState(initial_state)

  useEffect(() => {
    if (profileSaved && Object.keys(profileErrors).length === 0) {
      resetProfileFlagsRequest()
    }
  }, [profileSaved, profileErrors, resetProfileFlagsRequest])

  useEffect(() => {
    if (currentUser) {
      const {has_cards, has_completed_rider_profile} = currentUser.attributes
      const profile = currentUser.relationships.profile.attributes
      profile['is_rider'] = true
      setState(s => ({...s, profile}))

      if (!!has_cards && !!has_completed_rider_profile) navigate('/requests')
    }
  }, [currentUser, navigate])

  const displayImage = () => {
    const {profile} = currentUser.relationships
    if (profile && profile.relationships) {
      const {images} = profile.relationships

      const img = _.find(images, img => {
        return img.attributes.image_type === 'display'
      })
      return img ? img.attributes.url : missingImg
    }
  }

  const errorMessageFor = fieldName => {
    if (profileErrors && profileErrors[fieldName]) {
      return profileErrors[fieldName]
    }
  }

  const onToggleChange = (fieldName, checked) => {
    const {profile} = state
    let tmp = JSON.parse(JSON.stringify(profile))
    tmp[fieldName] = checked

    setState({
      ...state,
      profile: tmp,
    })
  }

  const uploadImage = (files, imageType) => {
    const fileObj = files[0]
    setProcessingRequest()

    if (fileObj) {
      var FR = new FileReader()
      FR.addEventListener('load', async e => {
        setProcessingRequest()
        const res = await uploadProfileImageRequest(imageType, e.target.result)
        if (res) {
          setUser(res.data)
        }
      })

      FR.readAsDataURL(fileObj)
    }
  }

  const {profile} = state
  const {has_cards} = currentUser.attributes

  return (
    <div className="edit-profile-page">
      <div className="container">
        <div className="row">
          <div className="col l3 s12 center-align">
            <div className="user-img-container">
              <img
                src={displayImage()}
                className="user-img responsive-img"
                alt=""
              />
            </div>
            <span className="error">{errorMessageFor('is_rider')}</span>
            <div className="mt20">
              <div className="bubble-container">
                {isProcessing && (
                  <ReactLoading
                    type="bubbles"
                    color="#3399ff"
                    height="100px"
                    width="100px"
                  />
                )}
              </div>
              <Dropzone
                onDrop={files => uploadImage(files, 'display')}
                onFileDialogCancel={() => {}}
                className="dropzone">
                {({getRootProps, getInputProps}) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div>
                        Try dropping image here, or click to select image to
                        upload. Size should be less than 3 MB.
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="row mt20 user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you allow smoking?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.smoking}
                  onChange={(checked, event, id) =>
                    onToggleChange('smoking', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you allow pets?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.pets}
                  onChange={(checked, event, id) =>
                    onToggleChange('pets', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Do you have or prefer ac?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.car_ac}
                  onChange={(checked, event, id) =>
                    onToggleChange('car_ac', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="row user-preference">
              <div className="col l9 s9 left-align">
                <span>Are you open to traveling with kids?</span>
              </div>
              <div className="col l3 s3 right-align">
                <Switch
                  checked={!!profile.kid_friendly}
                  onChange={(checked, event, id) =>
                    onToggleChange('kid_friendly', checked)
                  }
                  className="check-box"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
          </div>
          <div className="col offset-l1 l8 s12 right-side">
            <div className="row alert alert-success">
              Review your required profile data in order to request.
            </div>
            {!has_cards && (
              <div className="row mt20 ml0">
                <ProfileCardSection rideId={location.state.rideId} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RiderChecklist
