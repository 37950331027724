import React, {useState, useEffect, useCallback} from 'react'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import {Link, useNavigate} from 'react-router-dom'
import FacebookLogin from 'react-facebook-login'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import {PrimaryButton} from 'components/Buttons'
import SocialButton from 'components/SocialButton'
import {getGooglePeople} from 'apis/session'
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@material-ui/core'
import useUserStore from 'store/UserStore'
import useSessionStore from 'store/SessionStore'
import {useUser} from 'context/UserProvider'
import ReCAPTCHA from 'react-google-recaptcha'

const facebookId = process.env.REACT_APP_FACEBOOK_ID
const googleId = process.env.REACT_APP_GOOGLE_ID

const MAX_DATE = moment().subtract(18, 'years').toDate()

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
}

const gender = ['Male', 'Female', 'Other']

const initial_state = {
  email: '',
  password: '',
  loggedIn: false,
  loginCheck: false,
  user: {
    first_name: '',
    last_name: '',
    email: '',
    birthday: '',
    password: '',
    gender: '',
  },
  fbProcessing: false,
  googleProcessing: false,
  loginProcessing: false,
  modalOpen: false,
  modalFbOpen: false,
  googleUser: {},
  facebookUser: {},
  token: '',
}

const Login = () => {
  const navigate = useNavigate()
  const {setUser} = useUser()
  const {createUserRequest} = useUserStore()
  const {
    socialLoginRequest,
    setProcessingRequest,
    loginRequest,
    resendEmailVerificationRequest,
  } = useSessionStore()

  const [state, setState] = useState(initial_state)
  const [recaptchaError, setRecaptchaError] = useState('')

  const setSocialLoginProfile = useCallback(() => {
    let tmp = {...state.user}
    const socialLoginProvider = localStorage.getItem('socialLoginProvider')

    if (localStorage.socialLoginProfile) {
      const socialLoginProfile = JSON.parse(localStorage.socialLoginProfile)

      tmp.first_name = socialLoginProfile.firstName
      tmp.last_name = socialLoginProfile.lastName
      tmp.email = socialLoginProfile.email

      if (socialLoginProfile.gender) {
        tmp.gender =
          socialLoginProfile.gender.charAt(0).toUpperCase() +
          socialLoginProfile.gender.substr(1)
      }

      if (socialLoginProfile.birthday) {
        tmp.birthday = socialLoginProfile.birthday
      }

      if (socialLoginProfile.profilePicURL) {
        tmp.profile_image_url = socialLoginProfile.profilePicURL.replace(
          's96-c',
          's500-c',
        )
      }

      if (socialLoginProfile.link) {
        tmp.profile_link = socialLoginProfile.link
      }

      tmp.token = socialLoginProfile.id
      tmp.provider = socialLoginProvider

      setState((s) => ({...s, user: tmp}))
    }
    return tmp
  }, [state.user])

  useEffect(() => {
    setSocialLoginProfile()
  }, [setSocialLoginProfile])

  const onFieldChange = (fieldName, event) => {
    setState((s) => ({
      ...s,
      [fieldName]: event.target.value,
    }))
  }

  const onKeyPressEnter = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      handleLogin()
    }
  }

  const handleSocialLoginFailure = (err) => {}

  const handleFbSocialLogin = async (res) => {
    console.log(res)
    if (res.error) {
      handleSocialLoginFailure(res.error)
    } else {
      const user = {
        first_name: res.first_name,
        last_name: res.last_name,
        email: res.email,
        profile_image_url:
          res.picture && res.picture.data && res.picture.data.url
            ? res.picture &&
              res.picture.data &&
              res.picture.data.url.replace('s96-c', 's500-c')
            : '',
        profile_link: '',
        token: res.id,
        provider: 'facebook',
      }

      const result = await socialLoginRequest(
        'facebook',
        user.token,
        user.email,
      )

      if (!result) {
        setState((s) => ({
          ...s,
          fbProcessing: false,
        }))
      } else {
        if (result.errors && result.errors === 'Record not found') {
          setState((s) => ({
            ...s,
            facebookUser: user,
            modalFbOpen: true,
          }))
          return
        } else if (result.data) {
          setUser(result.data)
          setState((s) => ({
            ...s,
            fbProcessing: false,
          }))

          navigate('/search')
        } else {
          setState((s) => ({
            ...s,
            fbProcessing: false,
          }))
        }
      }
    }
  }

  const handleSocialLogin = async (user) => {
    setState((s) => ({
      ...s,
      googleProcessing: true,
    }))

    if (!user._profile.birthday || !user._profile.gender) {
      const peopleInfo = await getGooglePeople(
        user._profile.id,
        user._token.accessToken,
      )

      const {birthdays, genders} = peopleInfo || {}
      if (birthdays && birthdays.length > 0 && birthdays[0].date) {
        const {day, month, year} = birthdays[0].date
        user._profile.birthday = month + '/' + day + '/' + year
      }

      if (genders && genders.length > 0) {
        if (
          genders[0].formattedValue === 'Male' ||
          genders[0].formattedValue === 'Female'
        ) {
          user._profile.gender = genders[0].formattedValue
        } else {
          user._profile.gender = 'Other'
        }
      }
    }

    const {_provider, _profile} = user

    const result = await socialLoginRequest(
      _provider,
      _profile.id,
      _profile.email,
    )
    if (result.errors && result.errors === 'Record not found') {
      const user = composeUserFromGoogleProfile(_provider, _profile)

      if (!user.gender || !user.birthday) {
        setState((s) => ({
          ...s,
          googleUser: user,
          modalOpen: true,
        }))

        return
      }

      const createUserResult = await createUserRequest(user)
      setState((s) => ({
        ...s,
        googleProcessing: false,
      }))

      if (!createUserResult.errors) {
        setUser(createUserResult.data)
        navigate('/search')
      }
    } else if (result.data) {
      setState((s) => ({
        ...s,
        googleProcessing: false,
      }))

      setUser(result.data)

      navigate('/search')
    } else {
      setState((s) => ({
        ...s,
        googleProcessing: false,
      }))
    }
  }

  const handleLogin = async () => {
    const {email, password, token} = state
    setRecaptchaError('')

    if (!token) {
      setRecaptchaError('Invalid recaptcha response')
      return
    }

    setState((s) => ({...s, loginCheck: true, loginProcessing: true}))

    setProcessingRequest()
    const result = await loginRequest(email, password, token)
    if (result.errors) {
      if (result.errors === 'Invalid recaptcha response') {
        setState((s) => ({
          ...s,
          loginCheck: false,
          loginProcessing: false,
          error: '',
        }))
        setRecaptchaError('Invalid recaptcha response')
      } else {
        setState((s) => ({
          ...s,
          loginCheck: false,
          loginProcessing: false,
          error: result.errors || 'Invalid credentials',
        }))
      }
    } else {
      setState((s) => ({
        ...s,
        loginCheck: false,
        loginProcessing: false,
        error: '',
      }))
      setUser(result.data)

      navigate('/search')
    }
  }

  const sendVerificationEmail = async () => {
    const {email} = state

    setState((s) => ({
      ...s,
      emailError: null,
    }))
    if (!email) {
      setState((s) => ({
        ...s,
        emailError: 'Please enter a valid email.',
      }))
    } else {
      const result = await resendEmailVerificationRequest({identity: email})

      if (result) {
        navigate('/verify_email')
      }
    }
  }

  const handleFbReactLoading = () => {
    setState((s) => ({
      ...s,
      fbProcessing: true,
    }))
  }

  const composeUserFromGoogleProfile = (provider, profile) => {
    const user = {
      first_name: profile.firstName,
      last_name: profile.lastName,
      email: profile.email,
      gender: profile.gender,
      birthday: profile.birthday,
      profile_image_url: profile.profilePicURL.replace('s96-c', 's500-c'),
      profile_link: profile.link,
      token: profile.id,
      provider: provider,
    }
    return user
  }

  const continueGoogleSignup = async () => {
    setState((s) => ({
      ...s,
      modalOpen: false,
    }))

    const {googleUser} = state
    const createUserResult = await createUserRequest(googleUser)

    if (!createUserResult.errors) {
      setUser(createUserResult.data)
      navigate('/search')
    }
  }

  const continueFacebookSignup = async () => {
    setState((s) => ({
      ...s,
      modalFbOpen: false,
    }))

    const {facebookUser} = state
    const createUserResult = await createUserRequest(facebookUser)

    setState((s) => ({
      ...s,
      fbProcessing: false,
    }))

    if (!createUserResult.errors) {
      setUser(createUserResult.data)
      navigate('/search')
    }
  }

  const handleModalClose = () => {
    setState((s) => ({
      ...s,
      googleProcessing: false,
      modalOpen: false,
    }))
  }

  const {
    email,
    password,
    emailError,
    fbProcessing,
    googleProcessing,
    loginProcessing,
    modalOpen,
    googleUser,
    rerender,
    modalFbOpen,
    facebookUser,
  } = state
  return (
    <div className="login-container">
      <div className="container">
        <Card className="cardContainer">
          <h3 className="center-align">Login</h3>
          <div className="subHeading">
            As a Ridesurfing member, I will support an accepting environment
            that nurtures safety, trust, and friendship.
          </div>
          <div className="mb10">
            <FacebookLogin
              appId={facebookId}
              fields="first_name,last_name,email,picture.width(500).height(500)"
              scope="email,user_birthday,user_gender"
              callback={handleFbSocialLogin}
              textButton={
                fbProcessing ? 'Please wait...' : 'Sign in with Facebook'
              }
              cssClass="leftIcon-btn fb"
              isMobile={true}
              disableMobileRedirect={true}
              icon={<i className="fa fa-facebook-square icon mr10" />}
              onClick={() => handleFbReactLoading()}
            />
          </div>
          <div className="mb20">
            {!rerender ? (
              <SocialButton
                color="secondary"
                provider="google"
                appId={googleId}
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
                buttonName={
                  !!googleProcessing ? 'Please wait...' : 'Sign in with Google'
                }
                icon={<i className="fa fa-google icon mr10" />}
                className="leftIcon-btn ggl"
                scope="email profile https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/userinfo.profile"
              />
            ) : null}
          </div>
          <TextField
            fullWidth
            className="text-field"
            id="email"
            type="text"
            label="Email"
            margin="normal"
            value={email}
            onChange={(event) => onFieldChange('email', event)}
            onKeyPress={(event) => onKeyPressEnter(event)}
          />
          {!!emailError && <span className="error">{emailError}</span>}
          <TextField
            fullWidth
            className="text-field"
            id="password"
            type="password"
            label="Password"
            margin="normal"
            value={password}
            onChange={(event) => onFieldChange('password', event)}
            onKeyPress={(event) => onKeyPressEnter(event)}
          />
          {!!state.error && <span className="error">{state.error}</span>}
          {!!state.error && (
            <div className="terms-n-policy">
              If you have not verified your email yet,{' '}
              <a
                className="underline"
                href="#/"
                onClick={sendVerificationEmail}>
                click here
              </a>{' '}
              to receive a valid verification code.
            </div>
          )}

          <div className="mt10 mb10">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(token) => setState((s) => ({...s, token}))}
            />
            <span className="error">{recaptchaError}</span>
          </div>

          <div className="forgot-link">
            <Link className="underline" to="/forgot_password">
              Forgot password?
            </Link>
          </div>
          <div className="mt40">
            <PrimaryButton
              color="primary"
              buttonName={loginProcessing ? 'Please Wait...' : 'Login'}
              disabled={!!loginProcessing}
              className="leftIcon-btn login-btn"
              handleButtonClick={() => handleLogin()}
            />
          </div>
          <div className="signup-link">
            Don't have an account? Register{' '}
            <Link className="underline" to="/signup">
              here
            </Link>
          </div>
          <div className="terms-n-policy">
            I agree to the{' '}
            <Link className="underline" to="/terms">
              terms of service{' '}
            </Link>
            and
            <Link className="underline" to="/policies">
              {' '}
              privacy policy
            </Link>
          </div>
        </Card>
      </div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={handleModalClose}>
        <div style={style} className="profile-account-section">
          <div style={modalTitle}>Please fill your birthday and gender.</div>
          <div>
            <div className="date-picker-field">
              <DatePicker
                selected={
                  !!googleUser?.birthday ? new Date(googleUser.birthday) : ''
                }
                onChange={(date) => {
                  const newGoogleUser = {...googleUser}
                  newGoogleUser.birthday =
                    date.getMonth() +
                    1 +
                    '/' +
                    date.getDate() +
                    '/' +
                    date.getFullYear()
                  setState({
                    ...state,
                    googleUser: newGoogleUser,
                  })
                }}
                maxDate={MAX_DATE}
                showYearDropdown
                dropdownMode="select"
                placeholderText="MM/DD/YYYY"
                className="date-field text-field"
              />
            </div>
          </div>
          <div>
            <FormControl className="selectField">
              <InputLabel className="selectLabel" htmlFor="select-multiple">
                Select Gender
              </InputLabel>
              <Select
                value={googleUser?.gender}
                onChange={(event) => {
                  const newGoogleUser = {...googleUser}
                  newGoogleUser.gender = event.target.value
                  setState({
                    ...state,
                    googleUser: newGoogleUser,
                  })
                }}
                input={<Input id="select-multiple" />}
                MenuProps={MenuProps}
                className="selected-menu-field">
                {gender.map((name) => (
                  <MenuItem key={name} value={name} className="menu-field">
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={modalActionWrap}>
            <PrimaryButton
              disabled={!googleUser?.birthday || !googleUser?.gender}
              color="primary"
              buttonName="Continue"
              className="leftIcon-btn login-btn"
              handleButtonClick={continueGoogleSignup}
            />
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalFbOpen}
        onClose={handleModalClose}>
        <div style={style} className="profile-account-section">
          <div style={modalTitle}>Please fill your birthday and gender.</div>
          <div>
            <div className="date-picker-field">
              <DatePicker
                selected={
                  !!facebookUser?.birthday
                    ? new Date(facebookUser.birthday)
                    : ''
                }
                onChange={(date) => {
                  const newFacebookUser = {...facebookUser}
                  newFacebookUser.birthday =
                    date.getMonth() +
                    1 +
                    '/' +
                    date.getDate() +
                    '/' +
                    date.getFullYear()
                  setState({
                    ...state,
                    facebookUser: newFacebookUser,
                  })
                }}
                maxDate={MAX_DATE}
                showYearDropdown
                dropdownMode="select"
                placeholderText="MM/DD/YYYY"
                className="date-field text-field"
              />
            </div>
          </div>
          <div>
            <FormControl className="selectField">
              <InputLabel className="selectLabel" htmlFor="select-multiple">
                Select Gender
              </InputLabel>
              <Select
                value={facebookUser?.gender}
                onChange={(event) => {
                  const newFacebookUser = {...facebookUser}
                  newFacebookUser.gender = event.target.value
                  setState({
                    ...state,
                    facebookUser: newFacebookUser,
                  })
                }}
                input={<Input id="select-multiple" />}
                MenuProps={MenuProps}
                className="selected-menu-field">
                {gender.map((name) => (
                  <MenuItem key={name} value={name} className="menu-field">
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={modalActionWrap}>
            <PrimaryButton
              disabled={!facebookUser?.birthday || !facebookUser?.gender}
              color="primary"
              buttonName="Continue"
              className="leftIcon-btn login-btn"
              handleButtonClick={continueFacebookSignup}
            />
          </div>

          {/* <div style={modalActionWrap1}>
            <PrimaryButton
              color="secondary"
              buttonName="Close"
              className="leftIcon-btn"
              handleButtonClick={() => {
                this.handleSocialLoginFailure();
                this.handleModalClose();
                this.setState({
                  googleUser: {},
                  googleProcessing: false,
                });
              }}
              // disabled={!!signupProcessing}
              // handleButtonClick={() => this.handleSignup()}
            />
          </div> */}
        </div>
      </Modal>
    </div>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  backgroundColor: 'white',
  padding: 20,
  outline: 'none',
}

const modalTitle = {
  letterSpacing: '1px',
  textAlign: 'center',
  maxWidth: '100%',
  fontSize: 13,
  fontWeight: 600,
  color: '#002654',
  margin: '0 auto',
  marginBottom: 30,
}

const modalActionWrap = {
  marginTop: 20,
}

export default Login
